import React, { useContext } from 'react'
import TeamForm from './TeamForm'
import useAPI from '../hooks/useAPI'
import { editTeamOptions } from '../lib/apiOptions'
import { TeamDataContext } from '../store/TeamDataProvider'
import { useAtom } from 'jotai'
import { newTeam, updateTeamsAtom } from '../store/Teams'

const EditTeam = (props) => {
  let holdTeamData = null
  const { sendRequest, status, error } = useAPI(editTeamOptions)
  const [ ,dispatch] = useContext(TeamDataContext)
  const [, setTeamA] = useAtom(newTeam)
  const [, updateTeams] = useAtom(updateTeamsAtom)
  const editTeamHandler = (teamData) => {
    holdTeamData = teamData
    editTeamOptions.url = `${editTeamOptions.baseURL}\\${teamData.Team_id}`
    editTeamOptions.body = JSON.stringify(teamData)
    sendRequest(teamData)
  }

  const updateTeamStateHandler = () => {
    dispatch({type: 'SET_TEAM', payload: holdTeamData })
    setTeamA(holdTeamData)
    updateTeams(holdTeamData)
  }

  return(
    <TeamForm
      source='editTeam'
      teamData={props.teamData}
      onEditTeam={editTeamHandler}
      onUpdateTeam={updateTeamStateHandler}
      submitting={status}
      error={error}
      onBackClick={props.handleBackClick}
      onBackClickFromForm={props.onBackClick}
    />
  )
}

export default EditTeam
import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const MessageModal = (props) => {
  return(
    <Modal show={props.show} onHide={props.handleCloseMessageModal} >
      <Modal.Header closeButton><Modal.Title>{props.heading}</Modal.Title></Modal.Header>
      <Modal.Body>{props.message}</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={props.handleCloseMessageModal}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default MessageModal
import React, { useContext } from 'react'
import TeamContext from '../store/team-context'
// import { Prompt } from 'react-router-dom' //Prompt is not supported in react-router-dom v6 yet
import { Alert, Card, Form, Row, Col, Button } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'
import AlertDismissable from '../misc/AlertDismissable'
import { teamAtom,  } from '../store/Teams'
import { useAtom } from 'jotai'

const TeamForm = (props) => {
  //const [isBlocking, setIsBlocking] = useState(false) isBlocking is used with Prompt see above
  const teamCtx = useContext(TeamContext)
  const [teamA, ] = useAtom(teamAtom)
  let handler = props.onAddTeam
  let stateUpdateHandler = null
  let readOnly = false
  let teamData = {}

  if (props.source === 'editTeam') {
    teamData = props.teamData
    console.log('In TeamForm here is props.teamData: ', props.teamData)
    readOnly = true
    handler = props.onEditTeam
    stateUpdateHandler = props.onUpdateTeam
  } else {
    handler = props.onAddTeam
    // TODO: #1 add stateUpdateHandler
    stateUpdateHandler = props.onAddTeamToState
  }

  const formik = useFormik({
    initialValues: {
      Team_name: (teamData.Team_name ? teamData.Team_name : ''),
      Team_abrv: (teamData.Team_abrv ? teamData.Team_abrv : ''),
      Team_id: (teamData.Team_id ? teamData.Team_id : ''),
      Team_city: (teamData.Team_city ? teamData.Team_city : ''),
      Team_state: (teamData.Team_state ? teamData.Team_state : ''),
      Team_conference: (teamData.Team_conference ? teamData.Team_conference : 'AFC'),
      Team_division: (teamData.Team_division ? teamData.Team_division : 'East')
    },
    validationSchema: Yup.object({
      Team_name: Yup.string()
        .max(40, 'Must be 40 characters or less')
        .required('Required'),
      Team_abrv: Yup.string()
        .max(5, 'Must be 5 characters or less')
        .required('Required'),
      Team_id: Yup.number()
        .integer('Value must be an integer.')
        .required('Required'),
      Team_city: Yup.string()
        .max(17, 'Must be 17 characters or less')
        .required('Required'),
      Team_state: Yup.string()
        .max(13, 'No state has over 13, except Rhode Island and they are not getting a football team')
        .required('Required'),
      Team_conference: Yup.string()
        .required('Required'),
      Team_division: Yup.string()
        .required('Required')
    }),
    onSubmit: values => {
      handler(values)
      if (props.error) {
        console.log('here is error: ', props.error)
        return
      }
      stateUpdateHandler(values)
      //formik.resetForm()
      formik.setFieldValue('Team_conference', '')
      formik.setFieldTouched('Team_conference', false)
      //setIsBlocking(false)
    }
  })

  const handleChangeConference = (value) => {
    formik.setFieldValue('Team_conference', value.value)
    teamCtx.updateConference(value.value)
  }

  const handleBlurConference = (value) => {
    formik.setFieldTouched('Team_conference', true)
  }

  const handleChangeDivision = (value) => {
    formik.setFieldValue('Team_division', value.value)
  }

  const handleBlurDivision = (value) => {
    formik.setFieldTouched('Team_division', true)
  }

  const conferenceOptions = [
    { value: 'NFC', label: 'NFC' },
    { value: 'AFC', label: 'AFC' }
  ]

  const divisionOptions = [
    { value: 'North', label: 'North' },
    { value: 'South', label: 'South' },
    { value: 'East', label: 'East' },
    { value: 'West', label: 'West' }
  ]
  return (
    <>
      {/*<Prompt
        when={isBlocking}
        message={location => `Are you sure you want to go to ${location.pathname}`}
      />*/}
      {props.error && (
        <AlertDismissable message={props.error} variant='warning' />
      )}
      <Card bg='light' className='mt-3'>
      <Card.Header className='d-flex justify-content-between'>
          {props.source === 'editTeam' && (<>{teamData.Team_name}</>)}
          {props.source === 'newTeam' && ('New Team Form')}
          { props.source === 'editTeam' && (
            <Button variant='primary' size='sm' className='ms-3' onClick={() => props.onBackClickFromForm(teamA)}>Back</Button>)}
          {props.source === 'newTeam' && (
            <Button variant='primary' size='sm' className='ms-3' onClick={() => props.onBackClickFromNewTeamForm()}>Back!</Button>
          )}
      </Card.Header>
        <Card.Body>
        {props.submitting === 'completed' && props.error === null && (
        <AlertDismissable heading='Success!' message='team data successfully updated' variant='success' />
      )}
          <Form className='mt-3' onSubmit={formik.handleSubmit}>
            {props.submitting === 'pending' && !props.error && (
              <div className='d-flex align-items-center'>
                <strong>Submitting...</strong>
                <div className='spinner-border ms-auto' role='status' aria-hidden='true' />
              </div>
            )}
            <Form.Group controlId='Team_name'>
              <Form.Label>Team name</Form.Label>
              <Form.Control
                name='Team_name'
                type='text'
                onChange={e => {
                  formik.handleChange(e)
                  //setIsBlocking(e.target.value.length > 0)
                }}
                onBlur={formik.handleBlur}
                value={formik.values.Team_name}
                placeholder='Enter team name'
              />
              {formik.touched.Team_name && formik.errors.Team_name
                ? (<Alert variant='warning'>{formik.errors.Team_name}</Alert>)
                : null}
            </Form.Group>
            <Row>
              <Col>
                <Form.Group controlId='formTeamAbbreviation'>
                  <Form.Label className='mt-3'>Team abbreviation</Form.Label>
                  <Form.Control
                    name='Team_abrv'
                    type='Team_abrv'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Team_abrv}
                    placeholder='Enter team abbreviation'
                  />
                  {formik.touched.Team_abrv && formik.errors.Team_abrv
                    ? (<Alert variant='warning'>{formik.errors.Team_abrv}</Alert>)
                    : null}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId='Team_id'>
                  <Form.Label className='mt-3'>Team Id</Form.Label>
                  <Form.Control
                    name='Team_id'
                    type='number'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Team_id}
                    readOnly={readOnly}
                    placeholder='Enter team Id'
                  />
                  {formik.touched.Team_id && formik.errors.Team_id
                    ? (<Alert variant='warning'>{formik.errors.Team_id}</Alert>)
                    : null}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId='Team_city'>
                  <Form.Label className='mt-3'>Team City</Form.Label>
                  <Form.Control
                    name='Team_city'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Team_city}
                    placeholder='Enter team city'
                  />
                  {formik.touched.Team_city && formik.errors.Team_city
                    ? (<Alert variant='warning'>{formik.errors.Team_city}</Alert>)
                    : null}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId='Team_state'>
                  <Form.Label className='mt-3'>Team State</Form.Label>
                  <Form.Control
                    name='Team_state'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Team_state}
                    placeholder='Enter team State'
                  />
                  {formik.touched.Team_state && formik.errors.Team_state
                    ? (<Alert variant='warning'>{formik.errors.Team_state}</Alert>)
                    : null}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId='conference'>
                  <Form.Label className='mt-3'>Conference</Form.Label>
                  <Select
                    name='conference'
                    placeholder='Enter conference'
                    defaultValue={{ label: teamData.Team_conference, value: teamData.Team_conference }}
                    onChange={handleChangeConference}
                    onBlur={handleBlurConference}
                    options={conferenceOptions}
                  />
                  {formik.touched.Team_conference && formik.errors.Team_conference
                    ? (<Alert variant='warning'>{formik.errors.Team_conference}</Alert>)
                    : null}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId='division'>
                  <Form.Label className='mt-3'>Division</Form.Label>
                  <Select
                    name='division'
                    defaultValue={{ label: teamData.Team_division, value: teamData.Team_division }}
                    onChange={handleChangeDivision}
                    onBlur={handleBlurDivision}
                    options={divisionOptions}
                  />
                  {formik.touched.Team_division && formik.errors.Team_division
                    ? (<Alert variant='warning'>{formik.errors.Team_division}</Alert>)
                    : null}
                </Form.Group>
              </Col>
            </Row>
            <Row className='justify-content-md-center mt-3'>
              <Col md='auto'>
                {props.source === 'editTeam'
                  ? (<Button type='submit' className='btn-primary'>Update Team</Button>)
                  : <Button type='submit' className='btn-primary'>Create Team</Button>}
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  )
}

export default TeamForm
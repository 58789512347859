let teamBaseAPI;
let scheduleBaseAPI
let playerBaseAPI
let playbyplayBaseAPI
let teamCodesBaseAPI
//console.log('Here is env variable: ', process.env.REACT_APP_NOT_SECRET_CODE)
if (process.env.NODE_ENV === 'production') {
  teamBaseAPI = process.env.REACT_APP_TEAM_BASE_API
  scheduleBaseAPI = process.env.REACT_APP_SCHEDULE_BASE_API
  playerBaseAPI = process.env.REACT_APP_PLAYER_BASE_API
  playbyplayBaseAPI = process.env.REACT_APP_PLAYBYPLAY_BASE_API
  teamCodesBaseAPI = process.env.REACT_APP_TEAM_CODES_BASE_API
} else if (process.env.NODE_ENV === 'development') {
  teamBaseAPI = 'http://localhost:8080/teams'
  scheduleBaseAPI = 'http://localhost:8081/schedule'
  playerBaseAPI = 'http://localhost:8082/player'
  playbyplayBaseAPI = 'http://localhost:8085/playbyplay'
  teamCodesBaseAPI = 'http://localhost:8080/teamcodes'
}
export const teamOptions = {
  method: 'GET',
  audience: 'https://sportsdata.api',
  scope: 'read:teams',
  url: teamBaseAPI
}

export const editTeamOptions = {
  method: 'PUT',
  audience: 'https://sportsdata.api',
  scope: 'edit:teams',
  baseURL: teamBaseAPI,
  url: null,
  body: null
}

export const getTeamCodesOptions = {
  method: 'GET',
  audience: 'https://sportsdata.api',
  scope: 'read:teams',
  url: teamCodesBaseAPI
}

export const postTeamOptions = {
  method: 'POST',
  audience: 'https://sportsdata.api',
  scope: 'create:teams',
  url: teamBaseAPI,
  body: null
}

export const getScheduleOptions = {
  method: 'GET',
  audience: 'https://schedule/api',
  scope: 'read:schedule',
  baseUrl: scheduleBaseAPI,
  url: null
}

export const getScheduleByGameIDOptions = {
  method: 'GET',
  audience: 'https://sportsdata.api',
  baseUrl: scheduleBaseAPI,
  url: null
}

export const postScheduleOptions = {
  method: 'POST',
  body: null,
  audience: 'https://schedule/api',
  scope: 'read:schedule', // TODO fix scope to be create:schedule
  url: scheduleBaseAPI
}

export const putScheduleOptions = {
  method: 'PUT',
  body: null,
  audience: 'https://shedule/api',
  baseUrl: scheduleBaseAPI,
  url: null
}

export const getPlayerOptions = {
  method: 'GET',
  body: null,
  audience: 'https://sportsdata.api',
  baseUrl: playerBaseAPI,
  url: null
}

export const getPlayersByTeamOptions = {
  method: 'GET',
  body: null,
  audience: 'https://sportsdata.api',
  baseUrl: `${playerBaseAPI}/team`,
  url: null
}

export const getPlayersByTeamBySeasonOptions = {
  method: 'GET',
  body: null,
  audience: 'https://sportsdata.api',
  baseUrl: `${playerBaseAPI}/teambyseason`,
  url: null
}

export const postPlayerOptions = {
  method: 'POST',
  body: null,
  audience: 'https://sportsdata.api',
  url: playerBaseAPI
}

export const editPlayerOptions = {
  method: 'PUT',
  body: null,
  audience: 'https://sportsdata.api',
  baseUrl: playerBaseAPI,
  url: null
}

export const postPlayerTransactionOptions = {
  method: 'POST',
  body: null,
  audience: 'https://sportsdate.api',
  url: `https://player-service-w3ci3ir5fq-uc.a.run.app/player_transaction`
}

export const putPlayerTransactionOptions = {
  method: 'PUT',
  body: null,
  audience: 'https://sportsdate.api',
  baseUrl: `https://player-service-w3ci3ir5fq-uc.a.run.app/player_transaction`,
  url: null
}

export const getPlayerTransactionsOptions = {
  method: 'GET',
  body: null,
  audience: 'https://sportsdata.api',
  baseUrl: `https://player-service-w3ci3ir5fq-uc.a.run.app/player_transaction`,
  url: null
}

export const getPBPByGameID = {
  method: 'GET',
  body: null,
  audience: 'https://sportsdata.api',
  baseUrl: playbyplayBaseAPI,
  url: null
}

export const putPBPByGameIDPlayID = {
  method: 'PUT',
  body: null,
  audience: 'https://sportsdata.api',
  baseUrl: playbyplayBaseAPI,
  url: null
}

export const postPBPOptions = {
  method: 'POST',
  body: null,
  audience: 'https://sportsdata.api',
  url: playbyplayBaseAPI
}

export const deletePBPOptions = {
  method: 'DELETE',
  body: null,
  audience: 'https://sportsdata.api',
  baseUrl: playbyplayBaseAPI,
  url: null

}
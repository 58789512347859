import React from 'react'
import useAPI from '../hooks/useAPI'
import { postTeamOptions } from '../lib/apiOptions'
import TeamForm from './TeamForm'
import { useAtom } from 'jotai'
import { addTeamAtom } from '../store/Teams'


const NewTeam = (props) => {
  const { sendRequest, status, error } = useAPI(postTeamOptions)
  const [,addTeamToState] = useAtom(addTeamAtom)
  const addTeamHandler = (teamData) => {
    let controller = new AbortController()
    postTeamOptions.body = JSON.stringify(teamData)
    sendRequest(controller)
  }

  const addTeamStateHandler = (teamData) => {
    console.log('in NewTeam here is team to add to state: ', teamData)
    addTeamToState(teamData)
  }

  return (
    <TeamForm 
      source={'newTeam'}
      submitting={status}
      error={error}
      onAddTeam={addTeamHandler}
      onAddTeamToState={addTeamStateHandler}
      onBackClickFromNewTeamForm={props.onBackClickNewTeam}
    />
  )
}

export default NewTeam
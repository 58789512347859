import React from 'react'
import ScheduleCard from './ScheduleCard'

const ViewMatchup = (props) => {

  return (
    <ScheduleCard
      matchup={props.matchup}
      onView={props.onView}
      onBackClick={props.onBackClick}
    />
  )
}

export default ViewMatchup
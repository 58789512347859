import React from 'react'
import Container from 'react-bootstrap/Container'
import MainNavigation from './MainNavigation'
import DataDomainPicker from './DataDomainPicker'


const Layout = (props) => {
  return (
    <Container>
      <MainNavigation />
      <DataDomainPicker />
      <main>{props.children}</main>
    </Container>
  )
}

export default Layout
import React, { useState } from 'react'
import TeamContext from './team-context'

const TeamProvider = props => {
  const [teamState, setTeamState] = useState('AFC')
  const updateSelectedConference = conf => {
    setTeamState(conf)
  }
  const teamContext = {
    selectedConference: teamState,
    updateConference: updateSelectedConference,
  }
  return (
    <TeamContext.Provider value={teamContext}>
      {props.children}
    </TeamContext.Provider>
  )
}

export default TeamProvider
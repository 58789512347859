import React, { useState, useEffect, useContext } from 'react'
import { Container } from 'react-bootstrap'
import PlayerForm from './PlayerForm'
import useAPI from '../hooks/useAPI'
import ProfileContext from '../store/profile-context'
import { getTeamCodesOptions, postPlayerOptions } from '../lib/apiOptions'

const NewPlayer = (props) => {
  const emlCtx = useContext(ProfileContext)
  const [lastSubmittedPlayer, setLastSubmittedPlayer] = useState({})
  const { sendRequest: sendTeamRequest, status: teamLoading, error: teamError, data: TeamCodes } = useAPI(
    getTeamCodesOptions
  )

  useEffect(() => {
    const controller = new AbortController()
    const getTeams = async () => {
      sendTeamRequest(controller)
    }
    getTeams()
    return () => controller?.abort()
  }, [sendTeamRequest])

  const { sendRequest: postPlayerRequest, status: submitting, error: playerError } = useAPI(
    postPlayerOptions
  )
  const addPlayerHandler = (data) => {
    //console.log('here is data in player: ', data)
    const controller = new AbortController()
    postPlayerOptions.body = JSON.stringify(data)
    postPlayerRequest(controller)
    setLastSubmittedPlayer({ first: data.First_name, last: data.Last_name })
  }

  return (
    <>
      <Container>
        {teamLoading === 'pending' && (
          <div className='d-flex aign-items-center'>
            <strong>Loading...</strong>
            <div className='spinner-border ms-auto' role='status' aria-hidden='true' />
          </div>
        )}
      </Container>
      {teamLoading === 'completed' && teamError === null && (
        <PlayerForm
          source={'Add'}
          player={null}
          teamData={TeamCodes}
          handlePlayer={addPlayerHandler}
          submitting={submitting}
          playerError={playerError}
          lastHandledPlayer={lastSubmittedPlayer}
          email={emlCtx.userEmail}
          onBackClick={props.onBackClick}
        />
      )}
    </>
  )
}

export default NewPlayer
import React from 'react'
import Card from 'react-bootstrap/Card'
import ScheduleTable from './ScheduleTable'

const ScheduleBody = (props) => {
  console.log('here is status: ', props.status)
  return (
    <Card className='mt-3'>
      <Card.Header>{props.season} - {props.seasonType} </Card.Header>
      
        {props.status === 'pending' && (
          <div className='d-flex align-items-center'>
            <strong>Loading...</strong>
            <div className='spinner-border ms-auto' role='status' aria-hidden='true' />
          </div>
        )}
        <ScheduleTable matchups={props.schedule} onView={props.onView} onEdit={props.onEdit} />
      
    </Card>
  )
}

export default ScheduleBody
import React, { useEffect, useState } from 'react'
import ScheduleForm from './ScheduleForm'
import useAPI from '../hooks/useAPI'
import { putScheduleOptions, getScheduleByGameIDOptions, teamOptions } from '../lib/apiOptions'

const EditSchedule = (props) => {
  const {sendRequest: sendTeamRequest, status: teamLoading, error: teamError, data: TeamCodes} = useAPI(teamOptions)
  const {sendRequest: sendEditSchedule, status: scheduleLoading, error: scheduleEditError} = useAPI(putScheduleOptions)
  const {sendRequest: sendGetScheduleByID, status: updateLoading, error: updatedScheduleError, data: UpdatedSchedule} = useAPI(
    getScheduleByGameIDOptions)
  const [editUpdateIndex, setEditUpdateIndex] = useState(0)

  
  useEffect(() => {
    let controller = new AbortController()
    const getTeams = async () => {
      sendTeamRequest(controller)
    }
    getTeams()
    return () => controller.abort()
  },[sendTeamRequest])

  useEffect(() => {
    if (scheduleLoading === 'completed' && scheduleEditError === null) {
      //lets try forcing a refresh of the current matchups
      props.onScheduleUpdate(props.scheduleUpdateIndex + 1)

      //refresh the matchupObject after an update
      setEditUpdateIndex(editUpdateIndex + 1 )
      let controller = new AbortController()
      const getMatchup = async (c) => {
        getScheduleByGameIDOptions.url = `${getScheduleByGameIDOptions.baseUrl}\\${props.matchup.Game_id}`
        sendGetScheduleByID(c)
      }
      getMatchup(controller)
      return () => controller.abort()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[scheduleLoading, scheduleEditError])


  const editMatchupHandler = (data) => {
    console.log('In editMatchupHandler: ', data)
    let controller = new AbortController()
    putScheduleOptions.url = `${putScheduleOptions.baseUrl}\\${props.matchup.Game_id}`
    putScheduleOptions.body = JSON.stringify(data)
    sendEditSchedule(controller)
  }

  return (
    <>
      <div>
        {teamLoading === 'pending' && (
          <div className='d-flex align-items-center'>
            <strong>Loading...</strong>
            <div className='spinner-border ms-auto' role='status' aria-hidden='true' />
          </div>
        )}
        {updateLoading === 'pending' && (
          <div className='d-flex align-items-center'>
            <strong>Refreshing Form...</strong>
            <div className='spinner-border ms-auto' role='status' aria-hidden='true' />
          </div>
        )}
      </div>
      {teamLoading === 'completed' && editUpdateIndex === 0 &&(
        <ScheduleForm 
          source={"Edit"}
          matchup={props.matchup}
          loading={teamLoading}
          rawStatus={scheduleLoading}
          status={scheduleLoading === 'completed'}
          postError={scheduleEditError}
          teamError={teamError}
          teamNames={TeamCodes}
          handleMatchup={editMatchupHandler}
          onBackClick={props.onBackClick}
        />
      )}

      {editUpdateIndex > 0 && updateLoading === 'completed' && updatedScheduleError === null && (
        <ScheduleForm 
        source={"Edit"}
        matchup={UpdatedSchedule}
        loading={teamLoading}
        rawStatus={scheduleLoading}
        status={scheduleLoading === 'completed'}
        postError={scheduleEditError}
        teamError={teamError}
        teamNames={TeamCodes}
        handleMatchup={editMatchupHandler}
        onBackClick={props.onBackClick}
      />
      )}

    </>
  )
}

export default EditSchedule 
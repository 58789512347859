import React from 'react';
import { Card, Container } from 'react-bootstrap'
import { withAuthenticationRequired } from '@auth0/auth0-react'


const Home = () => {
  return (
    
    <Container>
      <Card className='mt-3' bg='primary' text='white'>
        <Card.Header>
          B.R.awk v 0.1!
        </Card.Header>
        <Card.Body>
          <Card.Title>Welcome to B.R.awk</Card.Title>
          <Card.Text>
            B.R.awk is a data management tool built specifically for inputting, editing and viewing football data.
            To get started, pick a specific data domain above.
          </Card.Text>
          <Card.Text>
            If you want to work with team data, pick the Teams domain and so on.
          </Card.Text>
          <Card.Text>
            B.R.awk is a work in progress and we've yet to launch the official version 1 release. Please send
            feedback to ryan.hawk@gmail.com.
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
    
  )
}

export default withAuthenticationRequired(Home, {
  onRedirection: () => <Home />
})

import { atom } from 'jotai'
import { focusAtom } from 'jotai/optics'


const dataAtom = atom({
  //teams: [{'Team_name':'', 'Team_abrv': '', 'Team_city':'', 'Team_state':'', 'Team_conference':'', 'Team_division': ''}],
  teams: [{}],
  team: '',
  isEditing: false
})

const updateItem = (array, data) => {
  let team = array.find((team) => team.Team_id === data.Team_id)
  let updatedTeam = {...team, ...data}
  let teamIndex = array.findIndex((team) => team.Team_id === data.Team_id)
  return [
    ...array.slice(0, teamIndex),
    updatedTeam,
    ...array.slice(++teamIndex)
  ]
}

export const teamsAtom = focusAtom(dataAtom, (optic) => optic.prop('teams'))
export const seedTeamsAtom = atom(
  () => "",
  (get, set, teams) => {
    //set(teamsAtom, [...get(teamsAtom), teams])
    set(teamsAtom, teams)
  }
)

export const updateTeamsAtom = atom(
  () => "",
  (get, set, team) => {
    set(teamsAtom, updateItem(get(teamsAtom), team))
  }
)
export const addTeamAtom = atom(
  () => "",
  (get, set, team) => {
    set(teamsAtom, [...get(teamsAtom),team,])
  }
)
export const teamAtom = focusAtom(dataAtom, (optic) => optic.prop('team'))
export const newTeam = atom(
  () => "",
  (get, set, team) => {
    set(teamAtom, team)
  }
)

export default dataAtom
import React, { useEffect, useState } from 'react'
import { Button, Container, Form, FormControl, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import TeamSelect from '../misc/TeamSelect'
import SeasonSelect from './SeasonSelect'
import Instruction from './Instruction'
import PlayerCard from './PlayerCard'
import PlayerTable from './PlayerTable'
import NewPlayer from './NewPlayer'
import EditPlayer from './EditPlayer'
import NoPlayer from './NoPlayer'
import PlayerTransactions from './PlayerTransactions'
import { teamOptions, getPlayerOptions, getPlayersByTeamBySeasonOptions } from '../lib/apiOptions'
import { seasonOptions } from './options'
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react'
import Home from '../Pages/Home'
import useAPI from '../hooks/useAPI'

const Player = () => {
  const { login, getAcccessTokenWithPopup } = useAuth0()
  const [showInstruction, setShowInstruction] = useState(true)
  const [selectedTeam, setSelectedTeam] = useState()
  const [selectedSeason, setSelectedSeason] = useState()
  const [showPlayerCard, setShowPlayerCard] = useState(false)
  const [showPlayerCardFromTable, setShowPlayerCardFromTable] = useState(false)
  const [showPlayersTable, setShowPlayersTable] = useState(false)
  const [showPlayerForm, setShowPlayerForm] = useState(false)
  const [showEditPlayer, setShowEditPlayer] = useState(false)
  const [showPlayerTransactions, setShowPlayerTransactions] = useState(false)
  const [selectedPlayer, setSelectedPlayer] = useState()
  const toggleInstruction = () => setShowInstruction(showInstruction => !showInstruction)
  const togglePlayerCardView = () => setShowPlayerCard(showPlayerCard => !showPlayerCard)
  const togglePlayerCardFromTableView = () => setShowPlayerCardFromTable(showPlayerCardFromTable => !showPlayerCardFromTable)
  const togglePlayersTableView = () => setShowPlayersTable(showPlayersTable => !showPlayersTable)
  const togglePlayerForm = () => setShowPlayerForm(showPlayerForm => !showPlayerForm)
  const toggleEditPlayerForm = () => setShowEditPlayer(showEditPlayer => !showEditPlayer)
  const togglePlayerTransactions = () => setShowPlayerTransactions(showPlayerTransactions => !showPlayerTransactions)

  const { sendRequest: sendTeamRequest, status: teamLoading, error: teamError, data: Teams } = useAPI(
    teamOptions
  )
  const { sendRequest: sendPlayerSearch, status: playerLoading, error: playerError, data: player } = useAPI(
    getPlayerOptions
  )
  const { sendRequest: sendTeamPlayersSearch, status: teamPlayersLoading, error: teamPlayerError, data: teamPlayers } = useAPI(
    getPlayersByTeamBySeasonOptions
  )

  let teamRequestCallCount = 0
  useEffect(() => {
    let controller = new AbortController()
    const getTeams = async () => {
      sendTeamRequest(controller)
    }
    getTeams()
    return () => controller.abort()
  }, [sendTeamRequest, teamRequestCallCount])

  const handleShowNewPlayer = (e) => {
    if (showInstruction) { toggleInstruction() }
    if (showPlayersTable) { togglePlayersTableView() }
    if (showPlayerCard) { togglePlayerCardView() }
    if (showPlayerForm) { togglePlayerForm() }
    if (showPlayerCardFromTable) { togglePlayerCardFromTableView() }
    if (showEditPlayer) { toggleEditPlayerForm() }
    if (showPlayerTransactions) { togglePlayerTransactions() }
    setSelectedPlayer(null)
    togglePlayerForm()
  }

  const handleShowInstructions = (e) => {
    if (showPlayerForm) { togglePlayerForm() }
    toggleInstruction()
  }

  const handleShowPlayerTableFromEdit = () => {
    if (showEditPlayer) { toggleEditPlayerForm() }
    setSelectedPlayer(null)
    togglePlayersTableView()

  }

  const handleShowPlayerTableFromCard = () => {
    if (showPlayerCard) { togglePlayerCardView() }
    setSelectedPlayer(null)
    togglePlayersTableView()
  }

  const handleShowPlayerTableFromTrans = () => {
    console.log('in back from trans table')
    if (showPlayerTransactions) { togglePlayerTransactions() }
    setSelectedPlayer(null)
    togglePlayersTableView()
  }

  useEffect(() => {
    if (teamPlayersLoading === 'pending' && !showPlayersTable) {
      if (showInstruction) { toggleInstruction() }
      if (showPlayerCard) { togglePlayerCardView() }
      if (showPlayerForm) { togglePlayerForm() }
      if (showPlayerTransactions) { togglePlayerTransactions() }
      if (showPlayerCardFromTable) {
        togglePlayerCardFromTableView()
        setSelectedPlayer(null)
      }
      if (showEditPlayer) {
        toggleEditPlayerForm()
        setSelectedPlayer(null)
      }
      togglePlayersTableView()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPlayerCard, teamPlayersLoading, showPlayersTable, showPlayerCardFromTable, showEditPlayer, showInstruction])

  useEffect(() => {
    if (playerLoading === 'pending' && !showPlayerCard) {
      if (showInstruction) { toggleInstruction() }
      if (showPlayersTable) { togglePlayersTableView() }
      if (showPlayerForm) { togglePlayerForm() }
      if (showPlayerTransactions) { togglePlayerTransactions() }
      if (showPlayerCardFromTable) {
        togglePlayerCardFromTableView()
        setSelectedPlayer(null)
      }
      if (showEditPlayer) {
        toggleEditPlayerForm()
        setSelectedPlayer(null)
      }
      togglePlayerCardView()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerLoading, showPlayersTable, showPlayerCard, showPlayerCardFromTable, showEditPlayer, showInstruction])

  const handleShowPlayerCard = (p) => {
    // this function turns on playercard. Possilbe its son from previous card view. Need to check...
    if (showPlayerCardFromTable) { togglePlayerCardFromTableView() }
    if (showPlayerTransactions) { togglePlayerTransactions() }
    setSelectedPlayer(p)
    if (showPlayersTable) { togglePlayersTableView() }
    if (showPlayerForm) { togglePlayerForm() }
    togglePlayerCardFromTableView()
  }

  const handleShowEditPlayer = (p) => {
    setSelectedPlayer(p)
    if (showPlayerCard) { togglePlayerCardView() }
    if (showPlayersTable) { togglePlayersTableView() }
    if (showPlayerForm) { togglePlayerForm() }
    if (showPlayerCardFromTable) { togglePlayerCardFromTableView() }
    if (showPlayerTransactions) { togglePlayerTransactions() }
    toggleEditPlayerForm()
  }

  const handleShowTransactions = (p) => {
    console.log('in handleShowTransactions here is player: ', p)
    setSelectedPlayer(p)
    if (showPlayerCard) { togglePlayerCardView() }
    if (showPlayersTable) { togglePlayersTableView() }
    if (showPlayerForm) { togglePlayerForm() }
    if (showPlayerCardFromTable) { togglePlayerCardFromTableView() }
    if (showEditPlayer) { toggleEditPlayerForm() }
    togglePlayerTransactions()
  }

  const handleSetTeam = (team) => { setSelectedTeam(team) }
  const handleSetSeason = (season) => { setSelectedSeason(season) }
  const clearSelectedSeason = () => setSelectedSeason()
  const clearSelectedTeam = () => setSelectedTeam()
  const handleSelectTeam = (e) => {
    console.log('In handleSelect Team: ', e)
    handleSetTeam(e)
    //let controller = new AbortController()
    //const apiURL = `${getPlayersByTeamOptions.baseUrl}\\${e}`
    //getPlayersByTeamOptions.url = apiURL
    //sendTeamSearch(controller)
  }

  const handleSelectSeason = (e) => {
    console.log('Here is season: ', e)
    handleSetSeason(e)
    if (selectedTeam !== '' && selectedSeason !== '') {
      console.log('SEARCHING!', selectedSeason, selectedTeam)
      handleGetPlayers()
    }
  }

  const handleGetPlayers = () => {

  }

  useEffect(() => {
    if (selectedTeam && selectedSeason) {
      let controller = new AbortController()
      const apiURL = `${getPlayersByTeamBySeasonOptions.baseUrl}\\${selectedTeam}\\${selectedSeason}`
      getPlayersByTeamBySeasonOptions.url = apiURL
      sendTeamPlayersSearch(controller)
      clearSelectedSeason()
      clearSelectedTeam()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeason, selectedTeam])

  /*useEffect(() => {
    if (selectedTeam !== '' && selectedSeason !== '') {
      let controller = new AbortController()
      const apiURL = `${getPlayersByTeamBySeasonOptions.baseUrl}\\${selectedTeam}\\${selectedSeason}`
      getPlayersByTeamBySeasonOptions.url = apiURL
      sendTeamPlayersSearch(controller)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeam, selectedSeason]) */

  const handlePlayerSearch = (e) => {
    e.preventDefault()
    var playerFullName = ''
    playerFullName = e.target.playerFullName.value
    let controller = new AbortController()
    const apiURL = `${getPlayerOptions.baseUrl}\\${playerFullName}`
    getPlayerOptions.url = apiURL
    sendPlayerSearch(controller)
    e.target.reset()
  }

  const handlePlayerSearchFromEdit = (playerName) => {
    let controller = new AbortController()
    const apiURL = `${getPlayerOptions.baseUrl}\\${playerName}`
    getPlayerOptions.url = apiURL
    sendPlayerSearch(controller)
  }

  const getTokenAndTryAgain = async () => {
    await getAcccessTokenWithPopup(teamOptions)
    teamRequestCallCount++
  }

  if (teamError) {
    console.log('we have teamError: ', teamError)
    if (teamError === 'login_required') {
      return (
        <Button onClick={() => login(teamOptions)}>Login</Button>
      )
    }
    if (teamError === 'Consent required') {
      return (
        <Button onClick={getTokenAndTryAgain}>Consent Required</Button>
      )
    }
  }


  return (
    <Container>
      <Navbar bg='light' className='mt-3' variant='light'>
        <Navbar.Toggle aria-controls='player-navbar' />
        <Navbar.Collapse id='player-navbar-collapse' >
          <Nav onSelect={handleShowNewPlayer} >
            <Nav.Item>
              <Nav.Link eventKey='newTeam'>Add Player</Nav.Link>
            </Nav.Item>
          </Nav>
          <Nav onSelect={handleSelectTeam}>
            <NavDropdown title="Select Team" id='team-dropdown'>
              {teamLoading === 'completed' && teamError === null && (
                Teams.map(team => <NavDropdown.Item eventKey={team.Team_id} key={team.Team_id}>
                  {team.Team_name}
                </NavDropdown.Item>)
              )}
            </NavDropdown>
          </Nav>
          <Nav onSelect={handleSelectSeason}>
            <NavDropdown title="Select Season" id='season-dropdown'>
              <SeasonSelect Seasons={seasonOptions} />
            </NavDropdown>
          </Nav>
          <Form className='d-flex ms-3' onSubmit={handlePlayerSearch}>
            <FormControl
              type='search'
              name='playerFullName'
              placeholder='Player Full Name'
              className='mr-2'
              aria-label='Search Player'
            />
            <Button type='submit' variant='primary'>Search</Button>
          </Form>
        </Navbar.Collapse>
      </Navbar>
      {teamPlayerError && (<NoPlayer loadError={teamPlayerError} />)}
      {(teamPlayersLoading === 'pending' || playerLoading === 'pending') && (
        <div className='d-flex align-items-center'>
          <strong>Loading...</strong>
          <div className='spinner-border ms-auto' role='status' aria-hidden='true' />
        </div>
      )}

      {showInstruction && (<Instruction />)}

      {showPlayerCardFromTable && selectedPlayer !== null && (
        <PlayerCard
          player={selectedPlayer}
          onEdit={handleShowEditPlayer}
          onBackClick={handleShowPlayerTableFromCard}
        />
      )}
      {showPlayerCard && playerLoading === 'completed' && (
        <PlayerCard
          player={player}
          playerError={playerError}
          onEdit={handleShowEditPlayer}
          onBackClick={handleShowPlayerTableFromCard}
        />
      )}
      {showPlayersTable && teamPlayersLoading === 'completed' && (
        <PlayerTable
          player={teamPlayers}
          onViewPlayer={handleShowPlayerCard}
          onEditPlayer={handleShowEditPlayer}
          onShowTransactions={handleShowTransactions}
        />
      )}
      {showPlayerForm && (
        <NewPlayer
          onBackClick={handleShowInstructions}
        />
      )}
      {showEditPlayer && (
        <EditPlayer
          player={selectedPlayer}
          onSuccessEdit={handlePlayerSearchFromEdit}
          onBackClick={handleShowPlayerTableFromEdit}
        />
      )}
      {showPlayerTransactions && (
        <PlayerTransactions
          player={selectedPlayer}
          onBack={handleShowPlayerTableFromTrans}
        />
      )}
    </Container>
  )

}

export default withAuthenticationRequired(Player, {
  onRedirection: () => <Home />
})
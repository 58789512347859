import React from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'

const ScheduleCard = (props) => {
  console.log('here is props matchup: ', props.matchup)
  return (
    <>
      <Card className='mt-3'>
        <Card.Header className='fw-bold d-flex justify-content-between'>
          {props.matchup.Away_team} @ {props.matchup.Home_team}
          <Button variant='primary' size='sm' className='ms-3' onClick={() => props.onBackClick()}>Back</Button>
        </Card.Header>
        <Card.Body>
          <Form>
          <Form.Group as={Row} xs='auto' className='mb-2'>
            <Form.Label column sm='2' className='fw-bold'>Game ID:</Form.Label>
            <Col sm='10'>
              <Form.Control
                readOnly
                plaintext
                value={props.matchup.Game_id}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} xs='auto' className='mb-2'>
            <Form.Label column sm='2' className='fw-bold'>Season Type:</Form.Label>
            <Col sm='10'>
              <Form.Control
                readOnly
                plaintext
                value={props.matchup.Season_type}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} xs='auto' className='mb-2'>
            <Form.Label column sm='2' className='fw-bold'>Season Year:</Form.Label>
            <Col sm='10'>
              <Form.Control
                readOnly
                plaintext
                value={props.matchup.Season_year}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} xs='auto' className='mb-2'>
            <Form.Label column sm='2' className='fw-bold'>Season Week:</Form.Label>
            <Col sm='10'>
              <Form.Control
                readOnly
                plaintext
                value={props.matchup.Week}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} xs='auto' className='mb-2'>
            <Form.Label column sm='2' className='fw-bold'>Season Date:</Form.Label>
            <Col sm='10'>
              <Form.Control
                readOnly
                plaintext
                value={props.matchup.Date}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} xs='auto' className='mb-2'>
            <Form.Label column sm='2' className='fw-bold'>Timezone:</Form.Label>
            <Col sm='10'>
              <Form.Control
                readOnly
                plaintext
                value={props.matchup.Timezone}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} xs='auto' className='mb-2'>
            <Form.Label column sm='2' className='fw-bold'>Start Time:</Form.Label>
            <Col sm='10'>
              <Form.Control
                readOnly
                plaintext
                value={props.matchup.Start_time}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} xs='auto' className='mb-2'>
            <Form.Label column sm='2' className='fw-bold'>Away Team:</Form.Label>
            <Col sm='10'>
              <Form.Control
                readOnly
                plaintext
                value={props.matchup.Away_team}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} xs='auto' className='mb-2'>
            <Form.Label column sm='2' className='fw-bold'>Away Team ID:</Form.Label>
            <Col sm='10'>
              <Form.Control
                readOnly
                plaintext
                value={props.matchup.Away_team_id}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} xs='auto' className='mb-2'>
            <Form.Label column sm='2' className='fw-bold'>Home Team:</Form.Label>
            <Col sm='10'>
              <Form.Control
                readOnly
                plaintext
                value={props.matchup.Home_team}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} xs='auto' className='mb-2'>
            <Form.Label column sm='2' className='fw-bold'>Home Team ID:</Form.Label>
            <Col sm='10'>
              <Form.Control
                readOnly
                plaintext
                value={props.matchup.Home_team_id}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} xs='auto' className='mb-2'>
            <Form.Label column sm='2' className='fw-bold'>Away Team Score:</Form.Label>
            <Col sm='10'>
              <Form.Control
                readOnly
                plaintext
                value={props.matchup.Away_team_score}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} xs='auto' className='mb-2'>
            <Form.Label column sm='2' className='fw-bold'>Home Team Score:</Form.Label>
            <Col sm='10'>
              <Form.Control
                readOnly
                plaintext
                value={props.matchup.Home_team_score}
              />
            </Col>
          </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </>
  )
}

export default ScheduleCard
import React from 'react'
import { Button, Table } from 'react-bootstrap'
import { Pencil, Eye } from 'react-bootstrap-icons'

const ScheduleTable = (props) => {
  return (
    <Table striped hover responsive size="sm">
      <thead>
        <tr key='tableHeaderRow'>
          <th></th>
          <th>Season</th>
          <th>Type</th>
          <th>Week</th>
          <th>Away</th>
          <th>Score</th>
          <th>Home</th>
          <th>Score</th>
          <th>Date</th>
          <th>Start Time</th>
          <th>Timezone</th>
        </tr>
      </thead>
      <tbody key='tableBody'>
        {props.matchups === null && (<tr><td>NO TEAMS</td></tr>)}
        {props.matchups != null && (props.matchups.map(match =>
          <tr key={match.Game_id}>
            <td>
              <Button variant='outline-dark' size="sm" className='ms-1 mt-1' onClick={() => props.onEdit(match)}><Pencil /></Button>
              <Button variant='outline-dark' size="sm" className='ms-1 mt-1' onClick={() => props.onView(match)}><Eye /></Button>
            </td>
            <td>{match.Season_year}</td>
            <td>{match.Season_type}</td>
            <td>{match.Week}</td>
            <td>{match.Away_team}</td>
            <td>{match.Away_team_score}</td>
            <td>{match.Home_team}</td>
            <td>{match.Home_team_score}</td>
            <td>{match.Date}</td>
            <td>{match.Start_time}</td>
            <td>{match.Timezone}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default ScheduleTable
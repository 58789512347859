import React, { useEffect, useState } from 'react'
import PlayerTransactionsTable from './PlayerTransactionsTable'
import PlayerTransactionsModal from './PlayerTransactionsModal'
import useAPI from '../hooks/useAPI'
import { getPlayerTransactionsOptions, postPlayerTransactionOptions, putPlayerTransactionOptions } from '../lib/apiOptions'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import AlertDismissable from '../misc/AlertDismissable'
import MessageModal from '../misc/MessageModal'


const PlayerTransactions = (props) => {
  const [source, setSource] = useState()
  const [transactionForEdit, setTransactionForEdit] = useState({})
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const [showMessageModal, setShowMessageModal] = useState(false)
  const handleCloseMessageModal = () => setShowMessageModal(false)

  const {sendRequest: playerTransactionsRequest, status: loadingTransactions, error: playerTransactionsError, data: playerTransactions} = 
    useAPI(getPlayerTransactionsOptions)
  const {sendRequest: postPlayerTransaction, status: submittingTransaction, error: postTransactionError} = useAPI(postPlayerTransactionOptions)
  const {sendRequest: putPlayerTransaction, status: editingTransaction, error: editPlayerTransactionError} = useAPI(putPlayerTransactionOptions)

  useEffect(() => {
    const controller = new AbortController()
    const getPlayerTransactions = async (controller) => {
      getPlayerTransactionsOptions.url = `${getPlayerTransactionsOptions.baseUrl}\\${props.player.Id}`
      playerTransactionsRequest(controller)
    }
    getPlayerTransactions(controller)
    return () => controller.abort()
  },[props.player.Id, playerTransactionsRequest])

  const handleShowModal = (transaction) => {
    if (transaction) {
      setTransactionForEdit(transaction)
      setSource('Edit')
    } else {
      setSource('Add')
    }
    setShowModal(true)
  }

  const postTransaction = (data) => {
    const controller = new AbortController()
    postPlayerTransactionOptions.body = JSON.stringify(data)
    postPlayerTransaction(controller)
  }

  const editTransaction = (id, data) => {
    const controller = new AbortController()
    putPlayerTransactionOptions.url = `${putPlayerTransactionOptions.baseUrl}\\${id}`
    putPlayerTransactionOptions.body = JSON.stringify(data)
    putPlayerTransaction(controller)
  }

  useEffect(() => {
    let controller = new AbortController()
    if ((submittingTransaction === 'completed' && postTransactionError === null) || 
    (editingTransaction === 'completed' && editPlayerTransactionError === null)) {
      setShowMessageModal(true)
      playerTransactionsRequest(controller)
    }  
    return () => controller.abort()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[submittingTransaction, postTransactionError, editingTransaction, editPlayerTransactionError])

  
  return (
    <>
      {loadingTransactions === 'pending' && (
        <><Row className=' align-items-baseline'>
          <Col><Spinner animation='border' variant='primary' />&nbsp;&nbsp;&nbsp;<strong>Loading...</strong></Col>
        </Row></>
      )}
      {editingTransaction === 'pending' && (
        <><Row className=' align-items-baseline'>
          <Col><Spinner animation='border' variant='primary' />&nbsp;&nbsp;&nbsp;<strong>Submitting Change to Transaction...</strong></Col>
        </Row></>
      )}
      {submittingTransaction === 'pending' && (
        <><Row className=' align-items-baseline'>
          <Col><Spinner animation='border' variant='primary' />&nbsp;&nbsp;&nbsp;<strong>Submitting new Transaction...</strong></Col>
        </Row></>
      )}
      { playerTransactionsError !== null && (
        <AlertDismissable
          variant='danger' heading='Issue getting Player Transactions' message={playerTransactionsError}
        />
      )}
      { postTransactionError !== null && (
        <AlertDismissable
          variant='danger' heading='Issue submitting new player transaction' message={postTransactionError}
        />
      )}
      { editPlayerTransactionError !== null && (
        <AlertDismissable
          variant='danger' heading='Issue submitting change' message={postTransactionError}
        />
      )}
      <MessageModal
        show={showMessageModal}
        handleCloseMessageModal={handleCloseMessageModal}
        heading="Success!"
        message={"Submission accepted!"}
      />
      {<PlayerTransactionsModal
          source={source}
          player={props.player}
          transaction={transactionForEdit}
          show={showModal}
          onHide={handleCloseModal}
          onPostTransaction={postTransaction}
          onEditTransaction={editTransaction}
      />}
      {loadingTransactions === 'completed' && (
        <PlayerTransactionsTable
          player={props.player} 
          transactions={playerTransactions}
          onEdit={handleShowModal}
          onBack={props.onBack}
        />
      )}
      <Button variant='outline-dark' className='mt-3' onClick={() => handleShowModal(null)}> + Add Transaction</Button>
    </>
  )
 

}

export default PlayerTransactions
import React from 'react'
import Alert from 'react-bootstrap/Alert'

const NoSchedule = (props) => {
  console.log('here is noschedule error: ', props.loadError)
  return (
    <Alert variant='danger'>Issue loading schedule: {props.loadError}</Alert>
  )
}

export default NoSchedule
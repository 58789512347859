import React, { useEffect } from 'react'
import Home from './Home'
import Container from 'react-bootstrap/Container'
import LoginButton from '../misc/LoginButton'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const { isAuthenticated } = useAuth0()
  let navigate = useNavigate()
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/home")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])
  console.log('here is isAuthenticate: ', isAuthenticated)
  

  return (
    <Container>
      <div className='bg-light p-5 rounded-lg m-3'>
        <h1 className='display-4'>Welcome to B.R.owk - our data management tool!</h1>
        <p className='lead'> B.R.owk is where you input new data points for the NFL. You can also review all data that has been entered in B.R.owk.</p>
        <p className='lead'>To gain access....well, you know who to call. If you don't, you don't need access.</p>
        <p><LoginButton /></p>
      </div>
    </Container>
  )
}

//export default Login
export default withAuthenticationRequired(Login, {
  onRedirection: () => <Home />
})
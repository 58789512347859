const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_TEAM':
      //console.log('in SET_TEAMS here is payload:', action.payload)
      return {
        ...state,
        teams: action.payload
      }
    case 'ADD_TEAM':
      return {
        ...state,
        teams: state.teams.concat(action.payload)
      }
    case 'UPDATE_TEAM':
      //console.log('here is state in UPDATE_TEAM', state)
      //console.log('here is payload in UPDATE_TEAM', action.payload)
      return {
        ...state,
        teams: action.payload
      }
    default:
      return state
  }
}

export default Reducer
import React from 'react'
import {Modal, Button} from 'react-bootstrap'

const ConfirmModal = (props) => {
  
  return (
    <Modal
      show={props.show}
      onHide={props.onCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.message}</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={props.onCloseModal}>Cancel</Button>
        <Button variant='primary' onClick={() => props.onSetConfirm(props.data)}>Confirm</Button>
      </Modal.Footer>

    </Modal>
  )
}

export default ConfirmModal
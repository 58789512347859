import React from 'react'
import { Button, Card, Table } from 'react-bootstrap'
import { Pencil, Eye} from 'react-bootstrap-icons'

const PlayerTransactionsTable = (props) => {

  return (
    <Card className='mt-5'>
      <Card.Header className="fw-bold d-flex justify-content-between">
        {props.player.Full_name} - Transaction History
        <Button variant='primary' size='sm' className='ms-3' onClick={() => props.onBack()}>Back</Button>
      </Card.Header>
      <Table striped hover responsive >
        <thead>
          <tr className="text-left">
            <th></th>
            <th>Transaction Id</th>
            <th>Season</th>
            <th>Season Type</th>
            <th>Week</th>
            <th>Transaction</th>
          </tr>
        </thead>
        <tbody key='tableBody'>
          {props.transactions === null && (<tr><td>NO TRANSACTIONS</td></tr>)}
          {props.transactions != null && (props.transactions.map(t => 
            <tr key={t.Insert_date} className="text-left">
              <td>
                <Button variant='outline-dark' size='sm' className='ms-1 mt-1' onClick={()=>props.onEdit(t)}><Pencil /></Button>
                <Button variant='outline-dark' size='sm' className='ms-1 mt-1' onClick={()=>console.log('placeholder for view')}><Eye /></Button>
              </td>
              <td >{t.Transaction_id}</td>
              <td>{t.Season}</td>
              <td>{t.Season_type}</td>
              <td>{t.Week}</td>
              <td>{t.Transaction_description}</td>
            </tr>  
          ))}
        </tbody>
      </Table>
    </Card>
  )
}

export default PlayerTransactionsTable 
import React, { useState, useEffect, useContext } from 'react'
import TeamContext from '../store/team-context'
import DivisionCard from './DivisionCard'
import Team from './Team'
import EditTeam from './EditTeam'
import NewTeam from './NewTeam'
import { Container, Nav, Navbar, NavDropdown, Row, Col } from 'react-bootstrap'
import NoTeams from './NoTeams'
import useAPI from '../hooks/useAPI'
import { teamOptions } from '../lib/apiOptions'
import { useAtom } from 'jotai'
import { seedTeamsAtom, teamsAtom, teamAtom, newTeam } from '../store/Teams'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import Home from '../Pages/Home'

const divisions = ['North', 'South', 'East', 'West']
const TeamsByDivision = () => {
  const { sendRequest, status: loading, error, data: Teams } = useAPI(
    teamOptions
  )

  const teamCtx = useContext(TeamContext)
  const [teamsA] = useAtom(teamsAtom)
  const [, seedTeams] = useAtom(seedTeamsAtom)
  const [teamA,] = useAtom(teamAtom)
  const [, setTeamA] = useAtom(newTeam)
  const [teams, setTeams] = useState([])
  const [showDivCards, setShowDivCards] = useState(true)
  const [showTeamView, setShowTeamView] = useState(false)
  const [showEditTeamForm, setShowEditTeamForm] = useState(false)
  const [showNewTeamForm, setShowNewTeamForm] = useState(false)
  const [, setTeam] = useState({})
  const toggleDivCards = () => setShowDivCards(showDivCards => !showDivCards)
  const toggleTeamView = () => setShowTeamView(showTeamView => !showTeamView)
  const toggleEditTeamForm = () => setShowEditTeamForm(showEditTeamForm => !showEditTeamForm)
  const toggleNewTeamForm = () => setShowNewTeamForm(showNewTeamForm => !showNewTeamForm)

  const handleShowTeamView = (team) => {
    setTeam(team)
    setTeamA(team)

    if (showDivCards) {
      toggleDivCards()
    }
    if (showEditTeamForm) {
      toggleEditTeamForm()
    }
    if (showNewTeamForm) {
      toggleNewTeamForm()
    }
    toggleTeamView()
  }

  const handleShowDivCards = () => {
    if (showEditTeamForm) {
      toggleEditTeamForm()
    }
    if (showNewTeamForm) {
      toggleNewTeamForm()
    }
    if (showTeamView) {
      toggleTeamView()
    }
    toggleDivCards()
  }

  const handleShowEditTeamForm = () => {
    toggleTeamView()
    toggleEditTeamForm()

  }

  const handleShowNewTeamForm = () => {
    if (showDivCards) {
      toggleDivCards()
    }
    if (showEditTeamForm) {
      toggleEditTeamForm()
    }
    toggleNewTeamForm()
  }
  const updateConferenceHandler = (key) => {
    teamCtx.updateConference(key)
  }

  useEffect(() => {
    let controller = new AbortController()
    const getTeams = async () => {
      sendRequest(controller)
    }
    getTeams()
    return () => controller?.abort()
  }, [sendRequest])

  useEffect(() => {
    if (loading === 'completed' && error === null) {
      seedTeams(Teams)
    }
  }, [loading, error, Teams, seedTeams])
  // TODO Delete the following:
  console.log('here is teams: ', teams)

  useEffect(() => {
    let isActive = true
    const newlyLoadedTeams = []

    if (Teams !== null) {
      if (loading === 'completed' && error === null) {
        divisions.forEach(div => {
          const teamList = []
          //Teams.forEach(team => {
          teamsA.forEach(team => {
            if (team.Team_conference === teamCtx.selectedConference && team.Team_division === div) {
              const teamObject = { ...team }
              teamList.push(teamObject)
            }
          })
          newlyLoadedTeams.push({ conference: teamCtx.selectedConference, division: div, teams: teamList })
        })
        const loadedTeams = []
        for (const team of newlyLoadedTeams) {
          if (team.conference === teamCtx.selectedConference) {
            loadedTeams.push({ conference: team.conference, division: team.division, teams: team.teams })
          }
        }
        if (isActive) {
          setTeams(loadedTeams)
        }
      }
    }
    return () => {
      isActive = false
    }
  }, [teamCtx.selectedConference, loading, error, Teams, teamsA])

  return (
    <Container>
      {showDivCards && (
        <>
          <Navbar bg='light' variant='light' className='mt-3'>
            <Navbar.Toggle />
            <Navbar.Collapse id='basic-navbar-nav'>
              <Nav className='mr.auto' onSelect={updateConferenceHandler}>
                <NavDropdown title='Conference' id='conference-dropdown'>
                  <NavDropdown.Item eventKey='AFC'>AFC</NavDropdown.Item>
                  <NavDropdown.Item eventKey='NFC'>NFC</NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Nav onSelect={handleShowNewTeamForm}>
                <Nav.Item>
                  <Nav.Link eventKey='newTeam'>New Team</Nav.Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          {loading === 'pending' && !error && (
            <div className='d-flex align-items-center'>
              <strong>Loading...</strong>
              <div className='spinner-border ms-auto' role='status' aria-hidden='true' />
            </div>
          )}
          {loading === 'completed' && Teams === null && (<><strong>no teams</strong></>)}
          {error && (<NoTeams loadError={error} />)}
          <Row className='mt-3 gap-2'>
            {teams.map((team) => (
              <Col key={team.division}>
                <DivisionCard team={team} onTeamClick={handleShowTeamView} />
              </Col>
            ))}
          </Row>
        </>
      )}
      {showTeamView && (<Team teamData={teamA} onBackClick={handleShowDivCards} onUpdateTeam={handleShowEditTeamForm} />)}
      {showEditTeamForm && (<EditTeam teamData={teamA} onEditTeam={setTeamA} onBackClick={handleShowTeamView} />)}
      {showNewTeamForm && (<NewTeam onBackClickNewTeam={handleShowDivCards} />)}
    </Container>
  )
}

export default withAuthenticationRequired(TeamsByDivision, {
  onRedirection: () => <Home />
})
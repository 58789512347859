import React from 'react'
import { Spinner } from 'react-bootstrap'

const SpinStatus = (props) => {
    console.log('here is spinstatus message: ', props.message)
  return (
    <>
      <div>
        <Spinner animation="border" role="status" />
          <strong>&nbsp;&nbsp;{props.message}</strong>
        </div>
    </>
  )
}

export default SpinStatus
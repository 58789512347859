import { useReducer, useCallback } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

function httpReducer(state, action) {
  if (action.type === 'SEND') {
    return {
      data: null,
      error: null,
      status: 'pending'
    }
  }
  if (action.type === 'SUCCESS') {
    return {
      data: action.data,
      error: null,
      status: 'completed'
    }
  }
  if (action.type === 'ERROR') {
    return {
      data: null,
      error: action.error,
      status: 'completed',
    }
  }

  return state
}

function useAPI(options, startWithPending = false) {
  //const controller = useMemo(() => new window.AbortController(),[])
  const [httpState, dispatch] = useReducer(httpReducer, {
    state: startWithPending ? 'pending' : null,
    data: null,
    error: null,
    //signal: controller
  })
  const { getAccessTokenSilently } = useAuth0()

  const sendRequest = useCallback(
    async function (controller, mounted) {
      dispatch({ type: 'SEND' })
      try {
        const { audience, scope, ...fetchOptions } = options
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(options.url, {
          signal: controller.signal,
          ...fetchOptions,
          headers: {
            ...fetchOptions.headers,
            Authorization: `Bearer ${accessToken}`
          }
        })
        let data = ''
        try {
          data = await res.json()
          // console.log('here is data: ', data)
          if (res.status >= 400) {
            console.log('error code: ', res.statusText)
            throw new Error(res.statusText)
          }
        } catch (error) {
          console.log('we have a request error: ', error)
          throw new Error(res.statusText)
        }

        if (mounted != null) {
          if (mounted.current) {
            //console.log('component is mounted')
            dispatch({ type: 'SUCCESS', data, controller: null })
          } else {
            console.log('component unmounted!')
            throw new Error('componentUnmounted')
          }
        } else {
          dispatch({ type: 'SUCCESS', data, controller: null })
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('successfully aborted!')
          return
        } else if (error.message === 'componentUnmounted') {
          console.log('component unmounted! Exiting!')
          return
        } else {
          console.log('here is error: ', error)
          dispatch({
            type: 'ERROR',
            error: error.message,
            //signal: controller
          })
        }
      }
    }, [options, getAccessTokenSilently]
  )
  return {
    sendRequest,
    ...httpState
  }
}

export default useAPI
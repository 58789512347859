import React from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import NoPlayer from './NoPlayer'

const PlayerCard = (props) => {
  console.log('in player card: ', props.player)
  console.log('is props.player null: ', props.player === null)
  const formatDOB = (date) => {
    const dateObj = new Date(date + 'T00:00:00')
    return new Intl.DateTimeFormat('en-US').format(dateObj)
  }

  return (
    <>
      {props.playerError && props.playerError === 'OK' && (<NoPlayer loadError={'No matching player found'} />)}
      {props.playerError && props.playerError !== 'OK' && (<NoPlayer loadError={props.playerError} />)}
      {props.player !== null && (
        <Card className='mt-3'>
          <Card.Header className='d-flex justify-content-between'>
            {props.player.Full_name}
            <Button variant='primary' size='sm' className='ms-3' onClick={() => props.onBackClick()}>Back</Button>
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Group as={Row} xs='auto' className='mb-2'>
                <Form.Label column sm='2'>First Name: </Form.Label>
                <Col sm='10'>
                  <Form.Control
                    readOnly
                    plaintext
                    value={props.player.First_name}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} xs='auto' className='mb-2'>
                <Form.Label column sm='2'>Last Name: </Form.Label>
                <Col sm='10'>
                  <Form.Control
                    readOnly
                    plaintext
                    value={props.player.Last_name}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} xs='auto' className='mb-2'>
                <Form.Label column sm='2'>Current Team: </Form.Label>
                <Col sm='10'>
                  <Form.Control
                    readOnly
                    plaintext
                    value={props.player.Current_team_name}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} xs='auto' className='mb-2'>
                <Form.Label column sm='2'>Number: </Form.Label>
                <Col sm='10'>
                  <Form.Control
                    readOnly
                    plaintext
                    value={props.player.Number}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} xs='auto' className='mb-2'>
                <Form.Label column sm='2'>Position: </Form.Label>
                <Col sm='10'>
                  <Form.Control
                    readOnly
                    plaintext
                    value={props.player.Position}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} xs='auto' className='mb-2'>
                <Form.Label column sm='2'>Date of Birth: </Form.Label>
                <Col sm='10'>
                  <Form.Control
                    readOnly
                    plaintext
                    value={formatDOB(props.player.Date_of_birth)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} xs='auto' className='mb-2'>
                <Form.Label column sm='2'>Height: </Form.Label>
                <Col sm='10'>
                  <Form.Control
                    readOnly
                    plaintext
                    value={`${props.player.Height_feet} ft ${props.player.Height_inches} inches`}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} xs='auto' className='mb-2'>
                <Form.Label column sm='2'>Weight: </Form.Label>
                <Col sm='10'>
                  <Form.Control
                    readOnly
                    plaintext
                    value={`${props.player.Weight_pounds} lbs`}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} xs='auto' className='mb-2'>
                <Form.Label column sm='2'>Draft Year: </Form.Label>
                <Col sm='10'>
                  <Form.Control
                    readOnly
                    plaintext
                    value={props.player.Draft_year}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} xs='auto' className='mb-2'>
                <Form.Label column sm='2'>Draft Round: </Form.Label>
                <Col sm='10'>
                  <Form.Control
                    readOnly
                    plaintext
                    value={props.player.Draft_round}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} xs='auto' className='mb-2'>
                <Form.Label column sm='2'>Draft Pick: </Form.Label>
                <Col sm='10'>
                  <Form.Control
                    readOnly
                    plaintext
                    value={props.player.Draft_pick}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} xs='auto' className='mb-2'>
                <Form.Label column sm='2'>Status: </Form.Label>
                <Col sm='10'>
                  <Form.Control
                    readOnly
                    plaintext
                    value={props.player.Status}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} xs='auto' className='mb-2'>
                <Form.Label column sm='2'>Season Record: </Form.Label>
                <Col sm='10'>
                  <Form.Control
                    readOnly
                    plaintext
                    value={props.player.Season}
                  />
                </Col>
              </Form.Group>
            </Form>
            <Row className='justify-content-md-center mt-3'>
              <Col md='auto'>
                <Button variant="primary" onClick={() => props.onEdit(props.player)}>Edit Player</Button>
              </Col>
            </Row>
          </Card.Body>

        </Card>
      )}
    </>
  )
}

export default PlayerCard
export const penaltyDownTable = {
  'CHB': 'replay',  // chop block
  'CLP': 'replay',  // clipping
  'DOD': 'replay',  // defensive delay of game
  'DH': 'first',    // defensive holding
  'DOF': 'replay',  // defensive offside
  'DPI': 'first',   // defensive pass interference 
  'DTM': 'replay',  // defensive too many men on the field 
  'DOG': 'replay',  // delay of game
  'DOK': 'replay',  // delay of kickoff
  'DSQ': '',        // disqualification
  'ENC': 'replay',  // encroachment
  'DFMM': 'first',  //  Defensive Facemask (15 yards)
  'FMM': 'replay',  // Offensive facemask (15 yards)
  'FCI': 'first',   // Fair catch interference
  'FST': 'replay',  // False start
  'HC': 'first',    // Horse Collar
  'BAT': 'lod',     // Illegal Bat
  'BLI': 'replay',  // Illegal Blindeside block
  'IBW': 'replay',  // Illegal Block Above the Waist
  'ICT': 'first',   // Illegal contact
  'ICB': 'replay',  // illegal crackback block
  'ICU': 'first',   // Illegal cut block
  'IDT': 'first',   // Illegal double-team block
  'ILF': 'replay',  // Illegal formation
  'IFHB': 'lod',    // Illegal forward handling beyond line of scrimmage   
  'IFH': 'replay',  // Illegal forward handling to inelligible receiver behind line of scrimmage
  'IFPB': 'lod',    // Illegal forward pass from BEYOND line of scrimmage
  'KIK': 'replay',  // Illegal kick / kicking loose ball
  'ILM': 'replay',  // Illegal motion
  'IPB': 'replay',  // Illegal peel back block
  'ISH': 'replay',  // Illegal shift
  'ILS': 'replay',  // Illegal substitution
  'ITKR': 'replay', // Illegal touching kick - rekick
  'ITKS': 'first',  // Illegal touching kick - spot foul - take possession where ball was touched.
  'ITPB': 'replay', // Illegal touching pass behind line of scrimmage, -5 at previous spot
  'ITP': 'lod',     // Illegal touching pass loss of down at previous spot
  'ILHO': 'replay', // Illegal use of hands by offense - loss of 10 yards
  'ILHD': 'first',  // Illegal use of hands by defense - 5 yards and automatic first down
  'IDK': 'replay',  // Ineligible receiver downfield - 5 yard penatly, rekick
  'IDP': 'replay',  // Ineligible downflield pass - loss of 5 yards
  'ING': 'lod',     // Intentional grounding - loss of down, 10 yard penalty
  'IFC': 'first',   // Invalid fair catch signal - spot foul
  'KOB': 'first',   // Kickoff out of bounds Spot of Kick  - receiving team elected to take possession of the ball 25 yards from spot of kickoff
  'LEAFG': 'first', // Leaping FG play - 15 yard penalty and automatic first down   
  'LEAXP': 'first', // Leaping Extra Point play - 15 yard penalty accessed on the kickoff
  'LEV': 'first',   // Leveraging
  'LBL': 'replay',  // low block - illegal block below the waist, 15 yard penalty
  'NZI': 'replay',  // Neutral zone infraction - 5 yard penalty 
  'OH': 'replay',   // offensive holding 
  'OOF': 'replay',  // Offensive offsides 5 yard penalty
  'OPI': 'replay',  // Offensive pass interference
  'OTM': 'replay',  // Offensive too many men
  'OFKY': 'first',  // Offsides on free kick - yardage taken
  'OFKR': 'replay', // Offsides on free kick - rekick
  'POK': 'first',   // Player out of bounds on kick
  'RRK': 'first',   // Roughing the kicker
  'RNK': 'replay',  // Running into the kicker
  'RPS': 'first',   // Roughing the passer
  'SFK': 'replay',  // Short free kick
  'TAUO': 'next',   // Taunting by offense
  'TAUD': 'first',  // Taunting by defense
  'TRPO': 'replay', // Tripping by offense
  'TRPD': 'first', // Tripping by defense
  'UNRO': 'replay', // Unnecessary roughness by offense
  'UNRD': 'first',  // Unnecessary roughness by defense
  'UNSO': 'next',   // Unsportsmanlike conduct offense
  'UNSD': 'first',  // Unsportsmanlike conduct defense
  'UOHO': 'next',   // Use of Helmet by offense
  'UOHD': 'first'  // Use of helmet by defense
}
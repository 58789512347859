import React, { createContext, useReducer } from 'react'
import Reducer from '../Teams/Reducer'

const initialState = {
  teams: null
}

const TeamDataProvider = ({children}) => {
  const [state, dispatch] = useReducer(Reducer, initialState) 
  return (
    <TeamDataContext.Provider value={[state, dispatch]}>
      {children}
    </TeamDataContext.Provider>
  )
}

export const TeamDataContext = createContext(initialState)
export default TeamDataProvider
import React from 'react'
import { Card, ListGroup, ListGroupItem, Nav } from 'react-bootstrap'

const DivisionCard = (props) => {
  const conference = props.team.conference
  const division = props.team.division
  const teams = props.team.teams

  const handleChange = (eventKey) => {
      const selectedTeam = props.team.teams.find(t => t.Team_name === eventKey)
      props.onTeamClick(selectedTeam)
  }

  return (
    <Card bg='light' style={{ width: '18rem' }} className='border-dark'>
      <Card.Header>{`${conference} - ${division}`}</Card.Header>
      <ListGroup className='list-group-flush' defaultActiveKey={teams.id}>
        {teams.map((team) => (
          /* <ListGroupItem value={team} key={team} action href={`#${team}`}>{team}</ListGroupItem> */
          <ListGroupItem 
            as={Nav} 
            action 
            variant='light' 
            value={team.Team_id} 
            key={team.Team_id} 
            onSelect={(selectedKey) => handleChange(selectedKey)}>
            <Nav.Link active eventKey={team.Team_name} className='link-secondary text-decoration-underline'>{team.Team_name}</Nav.Link>
          </ListGroupItem>
        ))}
      </ListGroup>
    </Card>

  )
}

export default DivisionCard
import React from 'react'
import { Card } from 'react-bootstrap'

const Instruction = () => {
  
  return (
    <Card className='mt-3'>
      <Card.Body>
        To view players Select a team from the above drop-down to see all active players on the selected team's roster.
      </Card.Body>
      <Card.Body>
        To add a new player record select 'Add Player' from above.
      </Card.Body>
    </Card>
  )
}

export default Instruction
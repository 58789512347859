import { penaltyDownTable } from "./penaltyDownTable"
import { penaltyYardsTable } from "./penaltyYardsTable"

export const getPenaltyNextDown = (lastPlay) => {
  const penaltyRuleDown = penaltyDownTable[lastPlay.Penalty_name] || ''
  const penaltyRuleYards = penaltyYardsTable[lastPlay.Penalty_name] || ''

  if (penaltyRuleDown === 'replay' && penaltyRuleYards !== '') {
    let first
    switch (lastPlay.Play_type) {
      case 'kickoff':
        first = true
        break;
      default:
        first = (lastPlay.Distance <= penaltyRuleYards)
        break;
    }
    return first ? 1 : lastPlay.Down
  }

  switch (penaltyRuleDown) {
    case 'lod':
      return lastPlay.Down + 1
    case 'first':
      return 1
    case 'next':
      return lastPlay.Down + 1
    default:
      return
  }
}

export const checkChangeOfPossession = (lastPlay) => {
  let yardsGained = lastPlay.Run_yards > 0 ? lastPlay.Run_yards : 0
  yardsGained = lastPlay.Pass_yards > 0 ? lastPlay.Run_yards : 0
  const changeOfPossession = lastPlay.Play_type === 'punt'
    || lastPlay.Play_type === 'kickoff'
    || lastPlay.Turnover
    || (lastPlay.Down === 4 && lastPlay.Distance < yardsGained)
  return changeOfPossession
}

export const getNextDown = (lastPlay) => {
  let yardsGained
  if (lastPlay.Play_type === 'timeout') {
    return lastPlay.Down
  }
  if (lastPlay.Play_type === 'run') {
    yardsGained = lastPlay.Run_yards > 0 ? lastPlay.Run_yards : 0
  } else if (lastPlay.Play_type === 'pass') {
    yardsGained = lastPlay.Pass_yards > 0 ? lastPlay.Pass_yards : 0
  }
  if (yardsGained >= lastPlay.Distance) return 1
  if (yardsGained <= lastPlay.Distance && lastPlay.Down === 4) return 1
  return lastPlay.Down + 1
}

export const getPenaltyNextDistance = (lastPlay) => {
  if (lastPlay.Penalty_name === 'DPI') return 10
  if (lastPlay.Penalty_name === 'DH') return 10
  if (lastPlay.Distance >= lastPlay.Penalty_yards && lastPlay.Penalty_team === lastPlay.Defense_team_name) { return 10 }

  if (lastPlay.Penalty_yards > 0) {
    // if penalty team is offense then make penalty yards a negative number
    const adjustPenaltyYards = lastPlay.Penalty_team === lastPlay.Offense_team_name ? lastPlay.Penalty_yards : -1 * lastPlay.Penalty_yards

    return ((lastPlay.Penalty_enforced_yardline + lastPlay.Distance) - (lastPlay.Penalty_enforced_yardline - adjustPenaltyYards))
  }

  const penaltyYards = penaltyYardsTable[lastPlay.Penalty_name] || ''
  return lastPlay.Distance + penaltyYards
}

export const getNextDistance = (lastPlay) => {
  if (lastPlay.Play_type === 'timeout') return lastPlay.Distance
  let yardsToGo
  // Offense is inside 10 yard line of redzone
  if (lastPlay.Offense_team_name !== lastPlay.Play_end_zone && lastPlay.Play_end_yardline < 10) {
    // Check if offense started before 10 yard line by checking if yards gained on last play is less than distance
    // if so then return differance as distance.
    if (lastPlay.Play_start_yardline - lastPlay.Play_end_yardline < lastPlay.Distance) {
      return lastPlay.Distance - (lastPlay.Play_start_yardline - lastPlay.Play_end_yardline)
    }
    return lastPlay.Play_end_yardline
  }

  if (lastPlay.Play_start_zone === lastPlay.Play_end_zone) {
    yardsToGo = lastPlay.Play_end_zone === lastPlay.Offense_team_name ?
      lastPlay.Distance - (lastPlay.Play_end_yardline - lastPlay.Play_start_yardline) :
      lastPlay.Distance - (lastPlay.Play_start_yardline - lastPlay.Play_end_yardline)
  } else {
    if (lastPlay.Pass_yards !== 0) {
      yardsToGo = lastPlay.Distance - lastPlay.Pass_yards
    } else if (lastPlay.Run_yards !== 0) {
      yardsToGo = lastPlay.Distance - lastPlay.Run_yards
    }
    else {
      yardsToGo = lastPlay.Distance - (100 - (lastPlay.Play_end_yardline + lastPlay.Play_start_yardline))
    }
  }

  if (lastPlay.Down === 4) {
    return getNextDown(lastPlay) === 1 ? 10 : lastPlay.Distance
  }

  return yardsToGo > 0 ? yardsToGo : 10
}

export const getNextStartYardline = (lastPlay, teamNameOptions) => {
  let newZone = Object.values(teamNameOptions).find((team) => team.value === lastPlay.Play_end_zone)

  let newStartYardline
  if (lastPlay.Play_type === 'kickoff') {
    newZone = { 'label': lastPlay.Kickoff_returned_zone, 'value': lastPlay.Kickoff_returned_zone }
    newStartYardline = lastPlay.Kickoff_returned_to
    return { newStartYardline, newZone }
  }

  if (lastPlay.Play_type === 'timeout') {
    newZone = { 'label': lastPlay.Play_start_zone, 'value': lastPlay.Play_start_zone }
    newStartYardline = lastPlay.Play_start_yardline
    return { newStartYardline, newZone }
  }

  if (lastPlay.Punt_touchback) {
    newZone = { 'label': lastPlay.Play_end_zone, 'value': lastPlay.Play_end_zone }
    newStartYardline = 20
    return { newStartYardline, newZone }
  }

  newStartYardline = lastPlay.Play_end_yardline
  return { newStartYardline, newZone }
}

export const getNextStartYLZonePenalty = (lastPlay, teamNameOptions) => {
  let newZone
  let newStartYardline

  let yardsTotal
  if (lastPlay.Penalty_team === lastPlay.Offense_team_name && lastPlay.Penalty_enforced_zone === lastPlay.Offense_team_name) {
    //console.log('1')
    yardsTotal = lastPlay.Penalty_enforced_yardline - lastPlay.Penalty_yards
  } else if (lastPlay.Penalty_team === lastPlay.Offense_team_name && lastPlay.Penalty_enforced_zone === lastPlay.Defense_team_name) {
    yardsTotal = lastPlay.Penalty_enforced_yardline + lastPlay.Penalty_yards
    //console.log('2')
  } else if (lastPlay.Penalty_team === lastPlay.Defense_team_name && lastPlay.Penalty_enforced_zone === lastPlay.Offense_team_name) {
    yardsTotal = lastPlay.Penalty_enforced_yardline + lastPlay.Penalty_yards
    //console.log('3')
  } else if (lastPlay.Penalty_team === lastPlay.Defense_team_name && lastPlay.Penalty_enforced_zone === lastPlay.Defense_team_name) {
    yardsTotal = lastPlay.Penalty_enforced_yardline - lastPlay.Penalty_yards
    //console.log('4')
  } else {
    yardsTotal = 0
  }

  newZone = yardsTotal > 50 ? Object.values(teamNameOptions).find((team) => team.value !== lastPlay.Play_end_zone) : Object.values(teamNameOptions).find((team) => team.value === lastPlay.Play_start_zone);
  if (newZone === undefined) {
    //handle when there is a penalty on kickoff and hence no previous Play_end_zone or Play_start_zone
    newZone = { label: lastPlay.Kickoff_returned_zone, value: lastPlay.Kickoff_returned_zone }
  }

  newStartYardline = yardsTotal > 50 ? 50 - (yardsTotal - 50) : yardsTotal
  return { newStartYardline, newZone }
}

function getSpotFoulYards(lastPlay) {
  // eslint-disable-next-line default-case
  switch (lastPlay.Penalty_name) {
    case 'DPI':
      return lastPlay.Penalty_yards
    case 'IFHB':
      return -5 // not right...probably same as 'DPI' look at it later
  }
}

export const getTeamNames = (lastPlay) => {
  if (lastPlay === null) {
    return { offense: '', defense: '' }
  }
  let offenseTeamName
  let defenseTeamName
  if ((lastPlay.Play_type === 'kickoff' && !lastPlay.Onside_kick) || lastPlay.Play_type === 'punt') {
    offenseTeamName = lastPlay.Defense_team_name
    defenseTeamName = lastPlay.Offense_team_name
  } else if (lastPlay.Onside_kick) {
    offenseTeamName = lastPlay.Offense_team_name === lastPlay.Onside_kick_recovered_team ? lastPlay.Offense_team_name : lastPlay.Defense_team_name
    defenseTeamName = lastPlay.Defense_team_name !== lastPlay.Onside_kick_recovered_team ? lastPlay.Defense_team_name : lastPlay.Offense_team_name
  } else if (lastPlay.Down === 4) {
    offenseTeamName = lastPlay.Defense_team_name
    defenseTeamName = lastPlay.Offense_team_name
  } else if (lastPlay.Turnover) {
    offenseTeamName = lastPlay.Defense_team_name
    defenseTeamName = lastPlay.Offense_team_name
  } else {
    offenseTeamName = lastPlay.Offense_team_name
    defenseTeamName = lastPlay.Defense_team_name
  }

  return { offense: offenseTeamName, defense: defenseTeamName }

}

export const getCharPosition = (string, subString, index) => {
  return string.split(subString, index)
}


import React, { useState } from 'react'
import { Accordion, Badge, Button, Card, Col, Row, Table } from 'react-bootstrap'
import PBPModal from './PBPModal'
import ConfirmModal from '../misc/ConfirmModal'
import NoPlays from './NoPlays'
import { Pencil, Trash, Eye } from 'react-bootstrap-icons'


const PBPView = (props) => {
  const homeTeam = props.home
  const homeTeamID = props.homeID;
  const awayTeam = props.away;
  const awayTeamID = props.awayID;
  const { homeAbrv, awayAbrv } = props
  let source = null
  // for form modal
  const [showModal, setShowModal] = useState(false)
  const [modalPlay, setModalPlay] = useState({})
  const handleCloseModal = () => setShowModal(false)

  const { gameID } = props
  let lastPlay;
  let maxPlayID;
  let maxSeriesID;
  let distinctSeries;
  let Clock_minutes;
  let Clock_seconds;
  let Home_team_score;
  let Away_team_score;
  const playsBySeries = [];
  // Check if there are plays for this matchup. If yes, get max play and series. If no, skip
  if (props.PlayByPlay !== null) {
    maxPlayID = Math.max(...props.PlayByPlay.map((p) => p.Play_id))
    maxSeriesID = Math.max(...props.PlayByPlay.map((p) => p.Series_id))
    // at(-1) return last item in array
    Clock_minutes = props.PlayByPlay.at(-1).Clock_minutes
    Clock_seconds = props.PlayByPlay.at(-1).Clock_seconds
    Home_team_score = props.PlayByPlay.at(-1).Home_team_score
    Away_team_score = props.PlayByPlay.at(-1).Away_team_score
    lastPlay = props.PlayByPlay.at(-1)
    distinctSeries = [...new Set(props.PlayByPlay.map(s => s.Series_id))]
    distinctSeries.forEach(series => {
      const holdPlays = []
      props.PlayByPlay.forEach(p => {
        if (p.Series_id === series) {
          const playObject = { ...p }
          holdPlays.push(playObject)
        }
      })
      playsBySeries.push({ series, plays: holdPlays })
    })
  } else {
    maxPlayID = 0
  }
  const handleShowModal = (play) => {
    if (play) {
      source = 'edit'
      setModalPlay({ play, source, homeTeam, homeTeamID, awayTeam, awayTeamID, homeAbrv, awayAbrv })
    } else {
      source = 'add'
      setModalPlay({
        lastPlay,
        source,
        maxSeriesID,
        maxPlayID,
        gameID,
        homeTeam,
        homeTeamID,
        homeAbrv,
        awayTeam,
        awayTeamID,
        awayAbrv,
        Clock_minutes,
        Clock_seconds,
        Home_team_score,
        Away_team_score
      })
    }
    setShowModal(true)
  }

  // prepend zero to clock seconds for better visual display
  const prependZero = (number) => {
    return number <= 9 ? `0${number}` : number;
  }

  return (
    <>

      {props.PlayByPlay === null && (<NoPlays />)}
      <PBPModal
        show={showModal}
        onHide={handleCloseModal}
        playsBySeries={playsBySeries}
        play={modalPlay}
        postPlay={props.postPlay}
        selectedSeason={props.selectedSeason}
        PBPPosting={props.PBPPosting}
        editPlay={props.editPlay}
        PBPEditing={props.PBPEditing}
        PBPEditError={props.PBPEditError}
        refreshPlays={props.refreshPlays}
      />
      <ConfirmModal
        show={props.show}
        onHide={props.onHide}
        title={'Confirm Delete'}
        message={'Are you sure you want to delete this play?'}
        data={props.deletePlay}
        onSetConfirm={props.onSetConfirm}
        onCloseModal={props.onHide}
      />
      {props.PlayByPlay === null && (
        <Card className='mt-3 mb-1' border='dark'>
          <Card.Header className='text-success'><strong>Game ID: {gameID} - {props.away} @ {props.home} - Week {props.selectedWeek}</strong> </Card.Header>
        </Card>
      )}
      {props.PlayByPlay !== null && (
        <>
          <Card className='mt-3 mb-1' border='dark'>
            <Card.Header className='text-success'><strong>Game ID: {gameID} - {props.away} @ {props.home} - Week {props.selectedWeek}</strong> </Card.Header>
          </Card>
          {playsBySeries.map((series) => {
            return <Card className='mb-2' bg={'dark'} bg-gradient='true' text={'light'} key={series.series}>
              <Card.Header style={{ height: '35px', fontSize: 14 }}><strong>Series: {series.series}</strong></Card.Header>

              <Accordion key={series.series}>

                <Accordion.Item eventKey={series.series} key={series.series}>
                  <Accordion.Header >
                    <Row xs="auto" style={{ fontSize: '16px' }}>
                      <Col>
                        <strong>{series.plays[0].Play_type === 'kickoff' ? series.plays[0].Defense_team_name :
                          series.plays[0].Offense_team_name}</strong>
                      </Col>
                      <Col>
                        # of Plays: &nbsp;&nbsp;{series.plays.length - series.plays.filter(p => {
                          return p.No_play === true || ['timeout', 'point after touchdown', 'kickoff', 'penalty', '2pc-pass', '2pc-run'].includes(p.Play_type)
                        }).length}
                      </Col>

                      <Col>
                        Quarter: {series.plays[0].Quarter}
                      </Col>

                      <Col>
                        Series Start:&nbsp;&nbsp;{series.plays[0].Play_type === 'kickoff' ?
                          `${series.plays[1] !== undefined ? series.plays[1].Clock_minutes : '00'}:${series.plays[1] !== undefined ? prependZero(series.plays[1].Clock_seconds) : '00'}` :
                          `${series.plays[0].Clock_minutes}:${prependZero(series.plays[0].Clock_seconds)}`}
                      </Col>
                      <Col>
                        {series.plays[0].Overtime_number > 0 ? <Badge pill bg='warning' text='dark'>Overtime</Badge> : null}
                      </Col>



                      <Col>
                        {series.plays.filter(p => p.Scoring_play).length > 0 ? <Badge pill bg='danger' text='light'> Scoring Play:


                          <strong><span className='text-decoration-underline'>
                            {series.plays.filter(p => p.Offensive_score_result > 1).length > 0 ? series.plays[series.plays.length - 1].Offense_team_name : null}
                            {/* Next evaluation is for when Defense scores on a turnover and you don't want to pick up the defense team name on the PAT so use series.plays.length - 2 vs. -1*/}
                            {series.plays.filter(p => p.Defensive_score_result > 1 && series.plays.length > 1).length > 0 ? series.plays[series.plays.length - 2].Defense_team_name : null}
                            {series.plays.filter(p => p.Defensive_score_result > 1 && series.plays.length === 1).length > 0 ? series.plays[series.plays.length - 1].Defense_team_name : null}
                          </span></strong></Badge> : null}
                      </Col>

                    </Row>
                    {/*series.plays[series.plays.length - 1].Play_description*/}
                  </Accordion.Header>
                  <Accordion.Body>
                    <Table responsive bordered hover variant='light' striped style={{ fontSize: 12 }} size='sm'>
                      <thead>
                        <tr key={series.series}>
                          <th className='col-2'>Actions</th>
                          <th>Quarter</th>
                          <th>play_id</th>
                          <th>down</th>
                          <td>distance</td>
                          <td>yardline</td>
                          <td>clock</td>
                          <th>offense</th>
                          <th>defense</th>
                          <th className='col-5'>play description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {series.plays.map((play) =>
                          <tr key={play.Play_id}>
                            <td>
                              <Button variant='outline-dark' size="sm" className='ms-1 mt-1 pt-0 pb-0 ps-1 pe-1' onClick={() => handleShowModal(play)}><Pencil /></Button>
                              <Button variant='outline-dark' size="sm" className='ms-1 mt-1 pt-0 pb-0 ps-1 pe-1' onClick={() => { props.handleShowConfirmModal(play) }}><Trash /></Button>
                              <Button variant='outline-dark' size="sm" className='ms-1 mt-1 pt-0 pb-0 ps-1 pe-1' onClick={() => alert('place holder to view play record only')}><Eye /></Button>
                            </td>
                            <td>{play.Quarter}</td>
                            <td>{play.Play_id}</td>
                            <td>{play.Down}</td>
                            <td>{play.Distance}</td>
                            <td>{play.Play_start_yardline}</td>
                            <td>{play.Clock_minutes}:{prependZero(play.Clock_seconds)}</td>
                            <td>{play.Offense_team_name}</td>
                            <td>{play.Defense_team_name}</td>
                            <td>{play.Play_description}</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>

                  </Accordion.Body>
                </Accordion.Item>

              </Accordion>
            </Card>;
          })}
        </>
      )
      }
      <Button variant='outline-dark' className='mt-1 mb-3' onClick={() => handleShowModal(null)}> + Add Play</Button>
    </>
  )
}

export default PBPView
export const directionTable = {
  'left': 'left',
  'left tackle': 'left-tackle',
  'left guard': 'left-guard',
  'left end': 'left-end',
  'short left': 'left-short',
  'deep left': 'left-deep',
  'right': 'right',
  'right guard': 'right-guard',
  'right tackle': 'right-tackle',
  'right end': 'right-end',
  'short right': 'right-short',
  'deep right': 'right-deep',
  'middle': 'middle',
  'short middle': 'middle-short',
  'deep middle': 'middle-deep'
}
import React from 'react'
import { Card, ListGroup, ListGroupItem, Nav } from 'react-bootstrap'

const PBPScheduleCard = (props) => {
  const matchups = props.Schedule

  return (
    <Card bg='light' className='mt-2'>
      <ListGroup className='list-group-flush' defaultActiveKey={matchups.Game_id}>
        {matchups.map((m) => (
          <ListGroupItem
            as={Nav}
            action
            variant='light'
            value={m.Game_id}
            key={m.Game_id}
            onSelect={(selectedKey) => props.onSelect(selectedKey, m.Away_team, m.Home_team, m.Away_team_id, m.Home_team_id)}
          >
            <Nav.Link
              active
              eventKey={m.Game_id}
              className='link-success text-decoration-underline'
            >
              <strong>{m.Away_team} @ {m.Home_team}</strong>
            </Nav.Link>
          </ListGroupItem>
        ))}
      </ListGroup>
    </Card>
  )
}

export default PBPScheduleCard
import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import history from './components/utils/history'
import Layout from './components/Layout/Layout'
import Login from './components/Pages/Login'
import Home from './components/Pages/Home'
import TeamsByDivision from './components/Teams/TeamsByDivision'
import Schedule from './components/Schedule/Schedule'
import Player from './components/Player/Player'
import PlayByPlay from './components/PlayByPlay/PlayByPlay'
import ProfileProvider from './components/store/ProfileProvider'
import TeamProvider from './components/store/TeamProvider'
import TeamDataProvider from './components/store/TeamDataProvider'
import { useAuth0 } from '@auth0/auth0-react'

function App() {
  const { isAuthenticated, isLoading, error } = useAuth0()
  if (error) {
    console.log('in app.js error page: ', isAuthenticated)
    return <div>Oops...{error.message}</div>
  }

  if (isLoading) {
    return <p>Loading...</p>
  }
  return (
    <Router history={history}>
      <ProfileProvider>
        <TeamDataProvider>
          <TeamProvider>
            <Layout>
              <Routes>
                {/*<Route path='/' exact>
            {isAuthenticated ? <Navigate to='/home'  /> : <Login />}
  </Route>*/}
                <Route ecact path='/' element={<Login />} />
                <Route path='/home' element={<Home />} />
                <Route path='/teams' element={<TeamsByDivision />} />
                <Route path='/schedule' element={<Schedule />} />
                <Route path='/player' element={<Player />} />
                <Route path='/playbyplay' element={<PlayByPlay />} />
              </Routes>
            </Layout>
          </TeamProvider>
        </TeamDataProvider>
      </ProfileProvider>
    </Router>
  )

}

export default App;

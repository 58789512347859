import React from 'react'
import { NavDropdown } from 'react-bootstrap'

const SeasonSelect = (props) => {
  const Seasons = props.Seasons
  return (
    <>
      {Seasons.map(season => (
        <NavDropdown.Item eventKey={season.value} key={season.value}>
          {season.value}
        </NavDropdown.Item>
      ))}
    </>
  )
}

export default SeasonSelect

import React, { useContext } from 'react'
import { Alert, Button, Card, Form, InputGroup, Row, Col } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'
import ProfileContext from '../store/profile-context'
import { seasonTypeOptions, seasonOptions, weekOptions, playerStatusOptions, trueFalseOptions } from './options'

const PlayerTransactionForm = (props) => {
  console.log('In PT Form here is transaction: ', props.transaction)
  let transaction = {}
  if (props.source === 'Edit') {
    transaction = props.transaction
  }

  const emlCtx = useContext(ProfileContext)
  const d = new Date()
  const currentYear = d.getFullYear()
  const currentMonth = d.getMonth()
  var currentSeason
  if (currentMonth === 0 || 1) {
    currentSeason = currentYear - 1
  } else {
    currentSeason = currentYear
  }
  console.log('Transaction id: ', transaction.Transaction_id)
  const formik = useFormik({
    initialValues: {
      id: props.player.Id,
      transaction_id: (transaction.Transaction_id ? transaction.Transaction_id : 0),
      first_name: props.player.First_name,
      last_name: props.player.Last_name,
      season: (transaction.Season ? transaction.Season : currentSeason),
      season_type: (transaction.Season_type ? transaction.Season_type : ''),
      week: (transaction.Week ? transaction.Week : 1),
      transaction_description: (transaction.Transaction_description ? transaction.Transaction_description : ''),
      status: (transaction.Status ? transaction.Status : ''),
      injury: (transaction.Injury ? transaction.Injury : false),
      injury_description: (transaction.Injury_description ? transaction.Injury_description : ''),
      team_name: props.player.Current_team_name,
      team_id: props.player.Current_team_id,
      entered_by: (emlCtx.userEmail)
    },
    validationSchema: Yup.object().shape({
      transaction_description: Yup.string().required('Please provide description of transaction').max(256),
      status: Yup.string().required('please select a status'),
      season_type: Yup.string().required('please select season type for transaction'),
      injury: Yup.boolean()
    }),
    onSubmit: values => {
      if (props.source === 'Add') {
        props.onPostTransaction(values, props.player.Id)
        props.onHide()
      }
      if (props.source === 'Edit') {
        console.log('EDIT VALUES: ', values)
        props.onEditTransaction(props.player.Id, values)
        props.onHide()
      }
    },
    validateOnChange: false,
    validateOnBlur: false
  })
  console.log('here if formik.values.injury: ', formik.values.injury)
  const handleChangeSelect = (value, name) => {
    formik.setFieldValue(name.name, value.value)
  }
  return (
    <Card className='mt-3'>
      <Card.Header as='h6' className='d-flex justify-content-between'>
        {props.source} Transaction for {props.player.Full_name}
      </Card.Header>
      <Card.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group as={Row} className='mb-4 right-align border-bottom' controlId='entered_by'>
            <Form.Label column sm={3} className='fw-bold'>
              Transaction Entered By:
            </Form.Label>
            <Col sm={4}>
              <Form.Control
                className='fw-bold'
                name='entered_by'
                plaintext
                readOnly
                type='email'
                placeholder={formik.values.entered_by}
                value={formik.values.entered_by}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-4 right-align border-bottom' controlId='transaction_id'>
            <Form.Label column sm={3} className='fw-bold'>
              Transaction ID:
            </Form.Label>
            <Col sm={4}>
              <Form.Control
                className='fw-bold'
                name='transaction_id'
                plaintext
                readOnly
                placeholder={formik.values.transaction_id}
                value={formik.values.transaction_id}
              />
            </Col>
          </Form.Group>
          <Row className='mb-3'>
            <Col>
              <Form.Group controlId='id'>
                <Form.Label>Player ID</Form.Label>
                <InputGroup>
                  <Form.Control
                    name="player_id"
                    value={formik.values.id}
                    type="text"
                    placeholder="Player ID"
                    readOnly={true}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="first_name">
                <Form.Label>First Name</Form.Label>
                <InputGroup>
                  <Form.Control
                    name="first_name"
                    type="text"
                    value={formik.values.first_name}
                    readOnly={true}
                    placeholder="First text" />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="last_name">
                <Form.Label>Last Name</Form.Label>
                <InputGroup>
                  <Form.Control
                    name="last_name"
                    type="text"
                    value={formik.values.last_name}
                    readOnly={true}
                    placeholder="Last name" />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <Form.Group controlId="season">
                <Form.Label>Season</Form.Label>
                <Select
                  name='season'
                  menuPlacement='top'
                  defaulValue={formik.values.season ? { label: formik.values.season, value: formik.values.season } : currentSeason}
                  options={seasonOptions}
                  onChange={handleChangeSelect}
                  placeholder={formik.values.season ? formik.values.season : currentSeason}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="season_type">
                <Form.Label>Season Type</Form.Label>
                <Select
                  name='season_type'
                  menuPlacement='top'
                  defaultValue={formik.values.season_type ? { label: formik.values.season_type, value: formik.values.season_type } : 'Select Season Type'}
                  options={seasonTypeOptions}
                  onChange={selectedOption => {
                    if (selectedOption) {
                      const event = { target: { name: 'season_type', value: selectedOption.value } }
                      formik.handleChange(event)
                      formik.setFieldError('season_type', "")
                    }
                  }}
                  placeholder={formik.values.season_type ? formik.values.season_type : 'Select Season Type'}
                />
                {formik.touched.season_type && formik.errors.season_type ?
                  (<Alert variant='danger'>{formik.errors.season_type}</Alert>) : null}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="week">
                <Form.Label>Week</Form.Label>
                <Select
                  name='week'
                  menuPlacement="top"
                  defaultValue={formik.values.week ? { label: formik.values.week, value: formik.values.week } : 'Select Week'}
                  options={weekOptions}
                  onChange={handleChangeSelect}
                  placeholder={formik.values.week ? formik.values.week : 'Select Week'}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <Form.Group controlId="status">
                <Form.Label>Status</Form.Label>
                <Select
                  name='status'
                  menuPlacement="top"
                  defaultValue={formik.values.status ? { label: formik.values.status, value: formik.values.status } : 'Select Status'}
                  options={playerStatusOptions}
                  onChange={handleChangeSelect}
                  placeholder={formik.values.status ? formik.values.status : 'Select Status'}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="injury">
                <Form.Label>Injury</Form.Label>
                <Select
                  name='injury'
                  menuPlacement="top"
                  defaultValue={formik.values.injury ? { label: formik.values.injury.toString(), value: formik.values.injury } : false}
                  options={trueFalseOptions}
                  onChange={handleChangeSelect}
                  placeholder={formik.values.injury ? formik.values.injury : 'Injury?'}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId='InjuryDescription'>
                <Form.Label>Injury Description</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    name='injury_description'
                    type='text'
                    value={formik.values.injury_description}
                    onChange={e => { formik.handleChange(e) }}
                    isInvalid={formik.errors.injury_description}
                    placeholder='Injury descripton'
                  />
                  <Form.Control.Feedback type='invalid'>{formik.errors.injury_description}</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <Form.Group controlId='TransactionDescription'>
                <Form.Label>Transaction Description</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    name='transaction_description'
                    type='text'
                    value={formik.values.transaction_description}
                    onChange={e => { formik.handleChange(e) }}
                    isInvalid={formik.errors.transaction_description}
                    placeholder='Enter transaction description'
                  />
                  <Form.Control.Feedback type='invalid'>{formik.errors.transaction_description}</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <Form.Group controlId='team_name'>
                <Form.Label>Team Name</Form.Label>
                <InputGroup>
                  <Form.Control
                    name="team_name"
                    value={formik.values.team_name}
                    type="text"
                    placeholder="Team Name"
                    readOnly={true}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId='team_id'>
                <Form.Label>Team ID:</Form.Label>
                <InputGroup>
                  <Form.Control
                    name="team_id"
                    value={formik.values.team_id}
                    type="text"
                    placeholder="Team Id"
                    readOnly={true}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row className='justify-content-md-center'>
            <Col lg='3' className='text-center'>
              <Button active='true' type='submit' className='mt-3 btn-primary'>Submit</Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  )
}

export default PlayerTransactionForm

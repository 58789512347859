import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import PBPForm from './PBPForm'
import AlertDismissible from '../misc/AlertDismissable'


const PBPModal = (props) => {
  const source = props.play.source
  var action
  if (props.play.source) {
    action = source.charAt(0).toUpperCase() + source.slice(1)
  }
  return (

    <Modal
      className='bg-secondary'
      show={props.show}
      onHide={props.onHide}
      scrollable={true}
      backdrop='static'
      size='xl'
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{action} Play</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.PBPEditError && (
          <AlertDismissible message={props.PBPEditError} variant='warning' />
        )}
        <PBPForm playsBySeries={props.playsBySeries}
          play={props.play}
          selectedSeason={props.selectedSeason}
          handleEditPlay={props.editPlay}
          handlePostPlay={props.postPlay}
          PBPEditing={props.PBPEditing}
          PBPPosting={props.PBPPosting}
          closeModal={props.onHide} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PBPModal
import React, { useState, useEffect } from 'react'
//import { Prompt } from 'react-router-dom' Prompt not supported in react-router-dom v6 yet
import { Alert, Button, Card, Form, InputGroup, Row, Col, Container } from 'react-bootstrap'
import AlertDismissible from '../misc/AlertDismissable'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Select, { components } from 'react-select'
import { positionOptions, statusOptions, seasonOptions } from './options'
import background from '../../images/T2Opacity15.png'

const PlayerForm = (props) => {
  var player = {}
  if (props.player !== null) {
    player = props.player
  }

  const selectErrorStyle = (err) => {
    const style = {
      control: (base, state) => ({
        ...base,
        borderColor: err ? '#DC3545' : '#CED4DA',
        background: state.isDisabled ? '#E9ECEF' : '#FFFFFF'
      }),
    }
    return style
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='18' height='18' fill='none' stroke='#dc3545'>
          <circle cx='6' cy='6' r='4.5' />
          <path strokeLinejoin='round' d='M5.8 3.6h.4L6 6.5z' />
          <circle cx='6' cy='8.2' r='.6' fill='#dc3545' stroke='none' /></svg>
      </components.DropdownIndicator>
    )
  }

  //const [isBlocking, //setIsBlocking] = useState(false) isBlocking is used with Prompt see above
  // const [selectedPosition, setSelectedPosition] = useState()
  const [value, setValue] = useState()

  const formik = useFormik({
    initialValues: {
      Id: (player.Id ? player.Id : ''),
      First_name: (player.First_name ? player.First_name : ''),
      Last_name: (player.Last_name ? player.Last_name : ''),
      Full_name: (player.Full_name ? player.Full_name : ''),
      Number: (player.Number ? player.Number : 0),
      Position: (player.Position ? player.Position : ''),
      Status: (player.Status ? player.Status : ''),
      Height_feet: (player.Height_feet ? player.Height_feet : 0),
      Height_inches: (player.Height_inches ? player.Height_inches : 0),
      Height_total_inches: (player.Height_total_inches ? player.Height_total_inches : 0),
      Weight_pounds: (player.Weight_pounds ? player.Weight_pounds : 0),
      Experience_years: (player.Experience_years ? player.Experience_years : 0),
      College: (player.College ? player.College : ''),
      Date_of_birth: (player.Date_of_birth ? player.Date_of_birth : ''),
      Draft_year: (player.Draft_year ? player.Draft_year : 0),
      Draft_round: (player.Draft_round ? player.Draft_round : 0),
      Draft_pick: (player.Draft_pick ? player.Draft_pick : 0),
      Current_team_name: (player.Current_team_name ? player.Current_team_name : ''),
      Current_team_id: (player.Current_team_id ? player.Current_team_id : 0),
      Draft_team_name: (player.Draft_team_name ? player.Draft_team_name : ''),
      Draft_team_id: (player.Draft_team_id ? player.Draft_team_id : 0),
      Season: (player.Season ? player.Season : 0),
      Entered_by: (player.Entered_by ? player.Entered_by : props.email)
    },
    validationSchema: Yup.object({
      First_name: Yup.string()
        .required('Required'),
      Last_name: Yup.string()
        .required('Required'),
      Full_name: Yup.string()
        .required('Required'),
      Number: Yup.number()
        .integer('values must be an integer')
        .moreThan(0, 'values must be greater than 0')
        .lessThan(100, 'value must be less than 100')
        .required('Required'),
      Position: Yup.string()
        .required('Required'),
      Status: Yup.string()
        .required('Required'),
      Height_feet: Yup.number()
        .integer('value must be an integer')
        .moreThan(0, 'height must be taller than 0')
        .required('Required'),
      Height_inches: Yup.number()
        .integer('value must be an integer'),
      Height_total_inches: Yup.number(),
      Weight_pounds: Yup.number()
        .integer('value must be an integer')
        .moreThan(0, 'must be greater than 0')
        .required('Required'),
      Experience_years: Yup.number()
        .integer('value must be an integer')
        .required('Required'),
      College: Yup.string()
        .required('Required'),
      Date_of_birth: Yup.date()
        .required('Required'),
      Draft_year: Yup.number()
        .integer('value must be an integer')
        .required('Required'),
      Draft_round: Yup.number().min(0, 'must be greater than 0')
        .integer('value must be an integer'),
      Draft_pick: Yup.number().min(0, 'must be greater than 0')
        .integer('value must be an integer'),
      Season: Yup.number()
        .moreThan(1989)
        .integer('value must be an integer')
        .required('season record year required'),
      Current_team_name: Yup.string(),
      Entered_by: Yup.string()
    }),
    onSubmit: values => {
      //console.log("here is values: ", values)
      props.handlePlayer(values)
      setValue(null)
      // clear the select values
      formik.setFieldValue('Position', '')
      formik.resetForm()
      //setIsBlocking(false)
    },
    validateOnChange: false,
    validateOnBlur: false
  })
  const teamId = []
  const teamOptions = []
  props.teamData.forEach(team => {
    const t = { ...team }
    teamOptions.push({ value: t.Team_name, label: t.Team_name })
    teamId.push({ teamID: t.Team_id, teamName: t.Team_name })
  })
  console.log('here is teamId: ', teamId)
  const handleChangeSelect = (value, name) => {
    if (value === null) {
      if (name.name === 'Draft_team_name') {
        formik.setFieldValue('Draft_team_id', 0)
      }
      formik.setFieldValue(name.name, '')
      return
    }

    formik.setFieldValue(name.name, value.value)
  }

  const handleAddOrEdit = (property, message) => {
    if (props.source === 'Edit') {
      return property !== null ? property : message;
    }
    if (props.source === 'Add') {
      return property !== "" ? property : message;
    }
  }
  useEffect(() => {
    const holdFirstName = formik.values.First_name
    formik.setFieldValue('First_name', holdFirstName.trim())

    const holdLastName = formik.values.Last_name
    formik.setFieldValue('Last_name', holdLastName.trim())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.First_name, formik.values.Last_name])
  useEffect(() => {
    if (formik.values.First_name && formik.values.Last_name && props.source === `Add`) {
      formik.setFieldValue('Full_name', `${formik.values.First_name} ${formik.values.Last_name}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.First_name, formik.values.Last_name])
  useEffect(() => {
    if (formik.values.Height_feet > 0) {
      const totalInches = (formik.values.Height_feet * 12) + formik.values.Height_inches
      formik.setFieldValue('Height_total_inches', totalInches)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.Height_feet, formik.values.Height_inches])
  useEffect(() => {
    if (formik.values.Current_team_name !== '') {
      let foundIndex = -1
      for (let i = 0; i < teamId.length; i++) {
        if (teamId[i].teamName === formik.values.Current_team_name) {
          foundIndex = i
          break
        }
      }
      formik.setFieldValue('Current_team_id', teamId[foundIndex].teamID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.Current_team_name])

  useEffect(() => {
    if (formik.values.Draft_team_name !== '') {

      let foundIndex = -1
      for (let i = 0; i < teamId.length; i++) {
        if (teamId[i].teamName === formik.values.Draft_team_name) {
          foundIndex = i
          break
        }
      }
      formik.setFieldValue('Draft_team_id', teamId[foundIndex].teamID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.Draft_team_name])

  const getAge = (dob) => {
    const dateObj = new Date(dob + 'T00:00:00')
    const diff = Date.now() - dateObj;
    const age = new Date(diff);

    return Math.abs(age.getUTCFullYear() - 1970)
  }
  return (
    <>
      {/*<Prompt
        when={isBlocking}
        message={location => `Are you sure you want to go to ${location.pathname}`}
      /> */}
      {props.submitting === 'completed' && props.playerError === null && (
        <AlertDismissible message={`${props.lastHandledPlayer.first} ${props.lastHandledPlayer.last} successfully ${props.source}ed`} variant='success' />
      )}
      {props.playerError && (
        <AlertDismissible message={props.playerError} variant='warning' />
      )}
      {props.submitting === 'pending' && (
        <div className='d-flex align-items-center'>
          <strong>Submitting player...</strong>
          <div className='spinner-border ms-auto' role='status' aria-hidden='true' />
        </div>
      )}
      <Card className='mt-3'>
        <Card.Header as='h6' className='d-flex justify-content-between'>
          {props.source} Player View
          <Button variant='primary' size='sm' className='ms-3' onClick={() => props.onBackClick()}>Back</Button>
        </Card.Header>
        <Card.Body>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Container style={{ backgroundImage: `url(${background})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center" }}>
              <Form.Group as={Row} className='mb-3 right-align' controlId='EnteredBy'>
                <Form.Label column sm={2}>
                  Player Entered By:
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    name='Entered_by'
                    plaintext
                    readOnly
                    type='email'
                    placeholder={props.email}
                    value={formik.values.Entered_by}
                  />
                </Col>
              </Form.Group>
              <Row className='mb-3'>
                <Col>
                  <Form.Group controlId='FirstName'>
                    <Form.Label>First Name</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='First_name'
                        type='text'
                        onChange={e => {
                          formik.handleChange(e)
                          //setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.First_name}
                        isInvalid={formik.errors.First_name}
                        placeholder='Enter First Name'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.First_name}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='LastName'>
                    <Form.Label>Last Name</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='Last_name'
                        type='text'
                        onChange={e => {
                          formik.handleChange(e)
                          //setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.Last_name}
                        isInvalid={formik.errors.Last_name}
                        placeholder='Enter Last Name'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.Last_name}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='FullName'>
                    <Form.Label>Full Name</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='Full_name'
                        type='text'
                        onChange={e => {
                          formik.handleChange(e)
                          //setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.Full_name}
                        isInvalid={formik.errors.Full_name}
                        placeholder='Enter Full Name'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.Full_name}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Group controlId='Number'>
                    <Form.Label>Number</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='Number'
                        type='number'
                        onChange={e => {
                          formik.handleChange(e)
                          //setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.Number}
                        isInvalid={formik.errors.Number}
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.Number}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Label>Position</Form.Label>
                  <Select
                    name='Position'
                    value={value}
                    placeholder={formik.values.Position ? formik.values.Position : 'Select position'}
                    options={positionOptions}
                    components={formik.errors.Position ? { DropdownIndicator } : null}
                    styles={selectErrorStyle(formik.errors.Position)}
                    onChange={selectedOption => {
                      if (selectedOption) {
                        const event = { target: { name: 'Position', value: selectedOption.value } }
                        formik.handleChange(event)
                        formik.setFieldError('Position', '')
                      } else {
                        formik.setFieldValue('Position', '')
                      }
                    }}
                  />
                  {formik.touched.Position && formik.errors.Position ?
                    (<div style={{ fontSize: '14px' }} className='mt-1 text-danger'>
                      {formik.errors.Position}
                    </div>) : null}
                </Col>
                <Col>
                  <Form.Group controlId='Status'>
                    <Form.Label>Status</Form.Label>
                    <Select
                      name='Status'
                      isSearchable
                      onChange={handleChangeSelect}
                      onBlur={() => formik.setFieldTouched('Status')}
                      value={value}
                      options={statusOptions}
                      placeholder={formik.values.Status ? formik.values.Status : 'Select status'}
                    />
                    {formik.touched.Status && formik.errors.Status
                      ? (<Alert variant='danger'>{formik.errors.Status}</Alert>)
                      : null}
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Group controlId='HeightFeet'>
                    <Form.Label>Height - Feet</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='Height_feet'
                        type='number'
                        onChange={e => {
                          formik.handleChange(e)
                          //setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.Height_feet}
                        isInvalid={formik.errors.Height_feet}
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.Height_feet}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='HeightInches'>
                    <Form.Label>Height - Inches</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='Height_inches'
                        type='number'
                        onChange={e => {
                          formik.handleChange(e)
                          //setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.Height_inches}
                        isInvalid={formik.errors.Height_inches}
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.Height_inches}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='HeightTotalInches'>
                    <Form.Label>Height - Total Inches</Form.Label>
                    <Form.Control
                      readOnly
                      name='Height_total_inches'
                      type='number'
                      value={formik.values.Height_total_inches}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Group controlId='Weight'>
                    <Form.Label>Weight - Pounds</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='Weight_pounds'
                        type='number'
                        onChange={e => {
                          formik.handleChange(e)
                          //setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.Weight_pounds}
                        isInvalid={formik.errors.Weight_pounds}
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.Weight_pounds}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='ExperienceYears'>
                    <Form.Label>Experience - Years</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='Experience_years'
                        type='number'
                        onChange={e => {
                          formik.handleChange(e)
                          //setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.Experience_years}
                        isInvalid={formik.errors.Experience_years}
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.Experience_years}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='College'>
                    <Form.Label>College</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='College'
                        type='text'
                        onChange={e => {
                          formik.handleChange(e)
                          //setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.College}
                        isInvalid={formik.errors.College}
                        placeholder='Enter college attended'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.College}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Group controlId='Date_of_birth'>
                    <Form.Label>Date of birth</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='Date_of_birth'
                        type='Date'
                        onChange={e => {
                          formik.handleChange(e)
                          //setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.Date_of_birth}
                        isInvalid={formik.errors.Date_of_birth}
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.Date_of_birth}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='DraftYear'>
                    <Form.Label>Draft Year</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='Draft_year'
                        type='number'
                        onChange={e => {
                          formik.handleChange(e)
                          //setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.Draft_year}
                        isInvalid={formik.errors.Draft_year}
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.Draft_year}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='DraftRound'>
                    <Form.Label>Draft Round</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='Draft_round'
                        type='number'
                        onChange={e => {
                          formik.handleChange(e)
                          //setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.Draft_round}
                        isInvalid={formik.errors.Draft_round}
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.Draft_round}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Group controlId='DraftPick'>
                    <Form.Label>Draft Pick</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='Draft_pick'
                        type='number'
                        onChange={e => {
                          formik.handleChange(e)
                          //setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.Draft_pick}
                        isInvalid={formik.errors.Draft_pick}
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.Draft_pick}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='DraftTeam'>
                    <Form.Label>Draft Team</Form.Label>
                    <Select
                      isClearable
                      name='Draft_team_name'
                      onChange={handleChangeSelect}
                      onBlur={() => formik.setFieldTouched('Draft_team_name')}
                      value={value}
                      options={teamOptions}
                      placeholder={formik.values.Draft_team_name ? formik.values.Draft_team_name : 'Select draft team'}
                    />
                    {formik.touched.Draft_team_name && formik.errors.Draft_team_name
                      ? (<Alert variant='danger'>{formik.errors.Draft_team_name}</Alert>)
                      : null}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='DraftTeamId'>
                    <Form.Label>Draft Team Id</Form.Label>
                    <Form.Control
                      readOnly
                      name='Draft_team_id'
                      type='number'
                      value={formik.values.Draft_team_id}
                    />
                  </Form.Group>

                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId='CurrentTeam'>
                    <Form.Label>Current Team</Form.Label>
                    <Select
                      name='Current_team_name'
                      menuPlacement='auto'
                      onChange={handleChangeSelect}
                      onBlur={() => formik.setFieldTouched('Current_team_name')}
                      value={value}
                      options={teamOptions}
                      placeholder={formik.values.Current_team_name ? formik.values.Current_team_name : 'Select current team'}
                    />
                    {formik.touched.Current_team_name && formik.errors.Current_team_name
                      ? (<Alert variant='danger'>{formik.errors.Current_team_name}</Alert>)
                      : null}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='CurrentTeamId'>
                    <Form.Label>Current Team Id</Form.Label>
                    <Form.Control
                      readOnly
                      name='Current_team_id'
                      type='number'
                      value={formik.values.Current_team_id}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='Season'>
                    <Form.Label>Season</Form.Label>
                    <Select
                      name='Season'
                      isSearchable
                      menuPlacement='auto'
                      value={value}
                      isDisabled={props.source === 'Edit'}
                      onChange={selectedOption => {
                        const event = { target: { name: 'Season', value: selectedOption.value } }
                        formik.handleChange(event)
                      }}
                      onBlur={() => formik.setFieldTouched('Season')}
                      options={seasonOptions}
                      placeholder={formik.values.Season ? formik.values.Season : 'select season for player record'}

                    />
                    {formik.touched.Season && formik.errors.Season
                      ? (<Alert variant='danger'>{formik.errors.Season}</Alert>)
                      : null}
                  </Form.Group>
                </Col>
              </Row>
              <Row className='justify-content-md-center'>
                <Col lg='3' className='text-center'>
                  <Button type='submit' className='mt-3 btn-primary' disabled={props.submitting === 'pending'}>Submit Player</Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </Card.Body>
      </Card>
    </>
  )
}

export default PlayerForm
// value based on how it impacts the offense's position on the field.
export const penaltyYardsTable = {
  'CHB': -15,
  'CLP': -15,
  'DOD': 5,
  'DH': 5,
  'DOF': 5,
  'DPI': 'spot foul',
  'DTM': 5,
  'DOG': -5,
  'DOK': -5,
  'DSQ': 0,
  'ENC': 5,
  'DFMM': 15,
  'FMM': -15,
  'FCI': 15,
  'FST': -5,
  'HC': 15,
  'BAT': -10,
  'BLI': -15,
  'IBW': -10,
  'ICT': 5,
  'ICB': -15,
  'ICU': 15,
  'IDT': -10,
  'ILF': -5,
  'IFHB': 'spot foul', // -5 yards
  'IFH': -5,
  'IFPB': -5,
  'KIK': -5,
  'ILM': -5,
  'IPB': -15,
  'ISH': -5,
  'ILS': -5,
  'ITKR': -5,
  'ITKS': 'spot foul',
  'ITPB': -5,
  'ITP': 0,
  'ILHO': -10,
  'ILHD': 5,
  'IDK': -5,
  'IDP': -5,
  'ING': -10,
  'IFC': 'spot foul', // -5
  'KOB': 'spot foul', // 25 yards from spot of kickoff
  'LEAFG': 15,
  'LEAXP': -15,
  'LEV': -15,
  'LBL': -15,
  'NZI': -5,
  'OH': -10,
  'OOF': -5,
  'OPI': -10,
  'OTM': -5,
  'OFKY': 5,
  'OFKR': 0,
  'POK': 5,
  'RRK': 15,
  'RNK': 5,
  'RPS': 15,
  'SFK': -5,
  'TAUO': -15,
  'TAUD': 15,
  'TRPO': -10,
  'TRPD': 10,
  'UNRO': -15,
  'UNRD': 15,
  'UNSO': -15,
  'UNSD': 15,
  'UOHO': -15,
  'UOHD': 15
}
import React, { useState } from 'react'
import ProfileContext from './profile-context'

const ProfileProvider = props => {
  const [emailState, setEmailState] = useState()
  const updateEmail = email => {
    setEmailState(email)
  }
  const emailContext = {
    userEmail: emailState,
    updateUserEmail: updateEmail
  }
  return (
    <ProfileContext.Provider value={emailContext}>
      {props.children}
    </ProfileContext.Provider>
  )
}

export default ProfileProvider
import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const DataDomainPicker = (props) => {

  return (
    <Container fluid>
      <Navbar bg='light' variant='light' className='dflex justify-content-between mt-2' expand='lg'>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="datadomainpicker">
          <Nav variant='tabs' defaultActiveKey="/teams">
            <Nav.Item>
              <LinkContainer to='/teams'>
                <Nav.Link eventKey='teams' >Teams</Nav.Link>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item>
              <LinkContainer to='/schedule'>
                <Nav.Link>Schedule</Nav.Link>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item>
              <LinkContainer to='/player'>
                <Nav.Link>Players</Nav.Link>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item>
              <LinkContainer to='/playbyplay'>
                <Nav.Link>PlayByPlay</Nav.Link>
              </LinkContainer>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  )
}

export default DataDomainPicker
import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'

const AlertDismissable = (props) => {
  const [show, setShow] = useState(true)

  if (show) {
    return (
      <Alert className='mt-3' variant={props.variant} onClose={() => setShow(false)} dismissible>
        <Alert.Heading>{props.heading}</Alert.Heading>
        <p>{props.message}</p>
      </Alert>
    )
  }
  return <> {null} </>
}

export default AlertDismissable
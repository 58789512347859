import React, { useEffect } from 'react'
import { Modal, ProgressBar } from 'react-bootstrap'

const StatusModal = (props) => {
  useEffect(() => {
    if (props.status === 'completed') {
      props.onHide()
    }
  })
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      centered
      background='static'
    >
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProgressBar animated now={75} />
      </Modal.Body>

    </Modal>
  )
}

export default StatusModal
import React from 'react'
import PlayerTransactionForm from './PlayerTransactionForm'
import { Modal, Button } from 'react-bootstrap'


const PlayerTransactionsModal = (props) => {
 console.log('here is props.source in modal: ', props.source)
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      scrollable={true}
      backdrop='static'
      size='xl'
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.source} Transaction</Modal.Title>
      </Modal.Header>
      <Modal.Body>
       <PlayerTransactionForm
        source={props.source}
        transaction={props.transaction}
        player={props.player}
        onPostTransaction={props.onPostTransaction}
        onEditTransaction={props.onEditTransaction}
        onHide={props.onHide}
       />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PlayerTransactionsModal
export const weeks = [
  '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18'
]

export const turnoverOptions = [
  { value: 'interception', label: 'interception' },
  { value: 'fumble', label: 'fumble' },
  { value: 'strip', label: 'strip' },
  { value: 'downs', label: 'downs' },
  { value: 'muffed punt', lable: 'muffed punt' },
  { value: 'dropped/touched/fumble punt/kick', label: 'dropped/touched/fumble punt/kick' },
]

export const personnelOptions = [
  { value: '00', label: '00' },
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '20', label: '20' },
  { value: '21', label: '21' },
  { value: '22', label: '22' },
  { value: '23', label: '23' },
]

export const qbPosition = [
  { value: 'shotgun', label: 'shotgun' },
  { value: 'under center', label: 'under center' },
  { value: 'wildcat', label: 'wildcat' },
  { value: 'pistol', label: 'pistol' }
]

export const playType = [
  { value: 'run', label: 'run' },
  { value: 'pass', label: 'pass' },
  { value: 'punt', label: 'punt' },
  { value: 'kickoff', label: 'kickoff' },
  { value: 'point after touchdown', label: 'point after touchdown' },
  { value: '2pc-run', label: '2pc-run' },
  { value: '2pc-pass', label: '2pc-pass' },
  { value: 'fieldgoal', label: 'fieldgoal' },
  { value: 'timeout', label: 'timeout' },
  { value: 'penalty', label: 'penalty' },
  { value: 'sack', label: 'sack' },
]

export const playDirections = [
  { value: 'short right', label: 'short right' },
  { value: 'short middle', label: 'short middle' },
  { value: 'short left', label: 'short left' },
  { value: 'right end', label: 'right end' },
  { value: 'left end', label: 'left end' },
  { value: 'deep right', label: 'deep right' },
  { value: 'deep middle', label: 'deep middle' },
  { value: 'deep left', label: 'deep left' },
  { value: 'right guard', label: 'right guard' },
  { value: 'left guard', label: 'left guard' },
  { value: 'right tackle', label: 'right tackle' },
  { value: 'left tackle', label: 'left tackle' },
  { value: 'left', label: 'left' },
  { value: 'left-tackle', label: 'left-tackle' },
  { value: 'left-guard', label: 'left-guard' },
  { value: 'left-end', label: 'left-end' },
  { value: 'left-short', label: 'left-short' },
  { value: 'left-deep', label: 'left-deep' },
  { value: 'right', label: 'right' },
  { value: 'right-guard', label: 'right-guard' },
  { value: 'right-tackle', label: 'right-tackle' },
  { value: 'right-end', label: 'right-end' },
  { value: 'right-short', label: 'right-short' },
  { value: 'right-deep', label: 'right-deep' },
  { value: 'middle', label: 'middle' },
  { value: 'middle-short', label: 'middle-short' },
  { value: 'middle-deep', label: 'middle-deep' }
]

export const penalties = [
  { value: 'CHB', label: 'chop block' },
  { value: 'CLP', label: 'clipping' },
  { value: 'DOF', label: 'defensive offsides' },
  { value: 'DPI', label: 'defensive pass interference' },
  { value: 'IBW', label: 'illegal block above waist' },
  { value: 'DOD', label: 'defensive delay of game' },
  { value: 'DH', label: 'defensive holding' },
  { value: 'DTM', label: 'defensive too many men on field' },
  { value: 'DOG', label: 'delay of game' },
  { value: 'DOK', label: 'delay of kickoff' },
  { value: 'DSQ', label: 'disqualification' },
  { value: 'ENC', label: 'encroachment' },
  { value: 'DFMM', label: 'defensive facemask - 15 yds' },
  { value: 'FMM', label: 'offensive facemask - 15 yds' },
  { value: 'FCI', label: 'fair catch interference' },
  { value: 'FST', label: 'false start' },
  { value: 'HC', label: 'horse collar' },
  { value: 'BAT', label: 'illegal bat' },
  { value: 'BLI', label: 'illegal blindside block' },
  { value: 'IBW', label: 'illegal block above the waist' },
  { value: 'ICT', label: 'illegal contact' },
  { value: 'ICB', label: 'illegal crackback block' },
  { value: 'ICU', label: 'illegal cut block' },
  { value: 'IDT', label: 'illegal double-team block' },
  { value: 'ILF', label: 'illegal formation' },
  { value: 'IFHB', label: 'illegal forward handling beyond los' },
  { value: 'IFH', label: 'illegal forward handling to inelligible receiver behind los' },
  { value: 'IFPB', label: 'illegal forward pass beyond los' },
  { value: 'KIK', label: 'illegal kick' },
  { value: 'ILM', label: 'illegal motion' },
  { value: 'IPB', label: 'illegal peel back block' },
  { value: 'ISH', label: 'illegal shift' },
  { value: 'ILS', label: 'illegal substitution' },
  { value: 'ITKR', label: 'illegal touching kick - rekick' },
  { value: 'ITKS', label: 'illegal touching kick - spot foul' },
  { value: 'ITPB', label: 'illegal touching pass behind los' },
  { value: 'ITP', label: 'illegal touching pass - loss of down' },
  { value: 'ILHO', label: 'illegal use of hands - offense' },
  { value: 'ILHD', label: 'illegal use of hands - defense' },
  { value: 'IDK', label: 'ineligible downfield kick' },
  { value: 'IDP', label: 'ineligible downfield pass' },
  { value: 'ING', label: 'intentional grounding' },
  { value: 'IFC', label: 'invalid fair catch signal' },
  { value: 'KOB', label: 'kickoff out of bounds' },
  { value: 'LEAFG', label: 'leaping fieldgoal play' },
  { value: 'LEAXP', label: 'leaping extra point play' },
  { value: 'LEV', label: 'leveraging' },
  { value: 'LBL', label: 'low block' },
  { value: 'NZI', label: 'neutral zone infraction' },
  { value: 'OH', label: 'offensive holding' },
  { value: 'OOF', label: 'offensive offsides' },
  { value: 'OPI', label: 'offensive pass interference' },
  { value: 'OTM', label: 'offensive too many men' },
  { value: 'OFKY', label: 'offsides on free kick yardage taken' },
  { value: 'OFKR', label: 'offsides on free kick - rekick' },
  { value: 'POK', label: 'player out of bounds on kick' },
  { value: 'RRK', label: 'roughing the kicker' },
  { value: 'RNK', label: 'running into the kicker' },
  { value: 'RPS', label: 'roughing the passer' },
  { value: 'SFK', label: 'short free kick' },
  { value: 'TAUO', label: 'taunting by offense' },
  { value: 'TAUD', label: 'taunting by defense' },
  { value: 'TRPO', label: 'tripping by offense' },
  { value: 'TRPD', label: 'tripping by defense' },
  { value: 'UNRO', label: 'unnecessary roughness by offense' },
  { value: 'UNRD', label: 'unnecessary roughness by defense' },
  { value: 'UNSO', label: 'unsportmanlike conduct offense' },
  { value: 'UNSD', label: 'unsportmanlike conduct defense' },
  { value: 'UOHO', label: 'use of helment offense' },
  { value: 'UOHD', label: 'use of helmet defense' },

]

export const defensiveFronts = [
  { value: '3-4', label: '3-4' },
  { value: '4-3', label: '4-3' },
  { value: '5-2', label: '5-2' },
  { value: '4-4', label: '4-4' },
  { value: '46', label: '46' },
  { value: 'Nickel', label: 'Nickel' },
  { value: 'Dime', label: 'Dime' }
]

export const coverageOptions = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '2-man', label: '2-man' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
]

export const incompletionTypes = [
  { value: 'drop', label: 'drop' },
  { value: 'bad throw', label: 'bad throw' },
  { value: 'knockdown', label: 'knockdown' },
  { value: 'pass interference', label: 'pass interference' },
  { value: 'tipped', label: 'tipped' },
  { value: 'throw away', label: 'throw away' },
  { value: 'spiked', label: 'spiked' },
  { value: 'coverage', label: 'coverage' },
  { value: 'batted at line', label: 'batted at line' },
  { value: 'na', label: 'na' }
]